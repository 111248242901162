import styled from "@emotion/styled";
import {
    Box,
    Button,
    Dialog as MuiDialog,
    DialogContent,
    Typography,
} from "@mui/material";
import React from "react";

export default function RevokeAccess({ deleteAdminApiCall, open, setDeleteAdmin }:
    { deleteAdminApiCall: () => void; open: boolean; setDeleteAdmin: (val: boolean) => void }) {
    const Dialog = styled(MuiDialog)(({ theme }) => {
        return {
            ".css-1o50bed-MuiPaper-root-MuiDialog-paper": {
                borderRadius: 16,
            },
        };
    });

    return (
        <Dialog open={open} sx={{ borderRadius: 16 }}>
            <DialogContent style={{ minWidth: "400px", borderRadius: 20 }}>
                <Typography variant="body1" fontWeight={600} textAlign={"center"} mt={3}>
                    Are you sure you want to delete <br /> this Client?
                </Typography>

                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-around"}
                    mt={3}
                >
                    <Button
                        onClick={() => {
                            setDeleteAdmin(false);
                        }}
                        variant="text"
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            deleteAdminApiCall?.();
                        }}
                        sx={{ minWidth: 200 }}
                        variant="contained"
                    >
                        Revoke access
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
