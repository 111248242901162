import { Box, IconButton, Typography, TextField as MuiTextField, InputAdornment } from "@mui/material";
import React, { useState, } from "react";
import DataGridChoicesResponses from "../data_grid_choices_responses/data_grid_choices_responses";
import FilterChoices from "../filter_choices/filter_choices";
import { useQuery } from "react-query";
import {
  downloadGetChoiceResponseList,
  getFilterData,
  getMasterData,
  getSubmittedChoices,
} from "../../../../services/choices/choices_services";
import XlsIcon from "../../../../assets/xls_icon.svg";
import FilterIcon from "../../../../assets/filter_icon.svg";
import { useParams } from "react-router-dom";
import { EnquiryLabels } from "../../../schemes/enquiries/enquiries_list";
import { downloadFromBlob } from "../../../../utils/utils";
import { RECORDS_EXCEEDS_ALERT_MSG } from "../../../../constants";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import Sort from "../../../../assets/sort.svg";
import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";

interface ChoicesResponse {
  // choicesResponse: { total: number; list: SubmittedChoices[] };
  currentTab: number;
}

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function ChoicesResponseList({
  // choicesResponse,
  currentTab,
}: ChoicesResponse) {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [openchoicesModel, setOpenChoicesModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [schemeKeyword, setSchemeKeyword] = useState("");
  const [stateId, setStateId] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [selectedGender, setSelectedGender] = useState<string>("");
  const [occupationId, setOccupationId] = useState<number | null>(null);
  const [educationId, setEducationId] = useState<number | null>(null);
  const [incomeId, setIncomeId] = useState<number | null>(null);
  const [ageFrom, setAgeFrom] = useState<number | null>(null);
  const [ageTo, setAgeTo] = useState<number | null>(null);
  const [familyIncomeId, setfamilyIncomeId] = useState<number | null>(null);
  const [sortInAcceding, setSortInAcceding] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const { data: masterDataList } = useQuery(["filter-Data-List"], async () => {
    let response = await getFilterData();
    return response.data.data;
  });
  const { showSnackbar } = useFeedback();

  const { data: choiceResponses, isLoading } = useQuery(
    [
      "choices-responses",
      ageFrom,
      ageTo,
      startDate,
      endDate,
      educationId,
      incomeId,
      occupationId,
      page,
      stateId,
      selectedGender,
      currentTab,
      familyIncomeId,
      sortInAcceding,
      searchValue,
    ],
    async () => {
      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      let response = await getSubmittedChoices({
        type: currentTab === 0 ? "user" : "family",
        page: page + 1,
        size: 8,
        currentIncome: incomeId,
        dateFrom: startDate,
        dateTo: endDate,
        education: educationId,
        familyIncome: familyIncomeId,
        fromAge: ageFrom,
        toAge: ageTo,
        gender: selectedGender,
        occupation: occupationId,
        state: stateId,
        choiceId: +id!,
        sortOrder: sortOrder,
        keyword: searchValue,
      });
      return response?.data?.data?.data || [];
    }
  );

  function downloadEnquiryApiCall() {
    let sortOrder = sortInAcceding ? "DESC" : "ASC";
    if (choiceResponses?.total <= 1000) {
      downloadGetChoiceResponseList({
        page: 1,
        size: 1000,
        type: currentTab === 0 ? "user" : "family",
        currentIncome: incomeId,
        dateFrom: startDate,
        dateTo: endDate,
        education: educationId,
        familyIncome: incomeId,
        fromAge: ageFrom,
        toAge: ageTo,
        gender: selectedGender,
        occupation: occupationId,
        state: stateId,
        format: "excel",
        choiceId: +id!,
        sortOrder: sortOrder
      })
        .then((res) => {
          let extension = ".xlsx";
          downloadFromBlob(res, extension);
        })
        .catch((e) => { });
    } else {
      showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
    }
  }

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }
  function searchComponent() {
    return (
      <Box mx={2} mt={2}>
        <TextField
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          size="small"
          fullWidth
          sx={{
            backgroundColor: "#F5E6F5",
            borderRadius: 3,
            borderColor: "transparent",
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "text.primary" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }
  let labels: EnquiryLabels[] = [
    {
      label: "UID",
    },
    {
      label: "Full Name",
    },
    {
      label: "Choice name",
    },
    {
      label: "Contact Number",
    },
    {
      label: "State",
    },
    {
      label: "District",
    },
    {
      label: "Education",
    },
    {
      label: "Occupation",
    },
    {
      label: "Submitted On",
    },
  ];

  return (
    <>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        p={2}
      >

        <Box display={"flex"} flexDirection={"row"}>
          <Typography display="flex" alignItems={"center"}>
            Download as:
          </Typography>
          {IconComponent(XlsIcon, "#E9F0ED", () => {
            downloadEnquiryApiCall();
          })}
        </Box>

        <Box display={"flex"} flexDirection={"row"}>
          <Box
            ml={2}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Typography>Sort By: </Typography>
            <Box
              ml={1}
              display="flex"
              flexDirection={"row"}
              alignItems="center"
              mr={1}
            >
              <Typography color={"#91278F"}>
                {sortInAcceding ? "New to old" : "Old to New"}
              </Typography>
              <IconButton
                onClick={() => {
                  setSortInAcceding(!sortInAcceding);
                }}
              >
                <img src={Sort} alt="" />
              </IconButton>
            </Box>
          </Box>
          <Typography
            display="flex"
            alignItems={"center"}
            color="#91278F"
            fontWeight="600"
            sx={{ borderBottom: "3px solid #91278F" }}
          >
            Filters:
          </Typography>
          {IconComponent(FilterIcon, "#E9F0ED", () => {
            setOpenChoicesModal(true);
          })}
        </Box>
      </Box>
      {/* {searchComponent()} */}
      <DataGridChoicesResponses
        labels={labels}
        count={choiceResponses?.total || 0}
        data={choiceResponses?.list || []}
        page={page}
        setPage={setPage}
        isLoading={isLoading}
      />

      <FilterChoices
        currentTab={currentTab}
        genderList={masterDataList?.gender}
        openchoicesModel={openchoicesModel}
        setOpenChoicesModal={setOpenChoicesModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        schemeKeyword={schemeKeyword}
        setSchemeKeyword={setSchemeKeyword}
        states={masterDataList?.state || []}
        occupation={masterDataList?.occupation}
        education={masterDataList?.education}
        income={masterDataList?.personalIncome}
        occupationId={occupationId}
        setOccupationId={setOccupationId}
        educationId={educationId}
        setEducationId={setEducationId}
        incomeId={incomeId}
        setIncomeId={setIncomeId}
        stateId={stateId}
        setStateId={setStateId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        resetFilter={() => {
          setCategoryId(null);
          setStateId(null);
          setSchemeKeyword("");
          setStartDate("");
          setEndDate("");
          setSelectedGender("");
          setOccupationId(null);
          setAgeFrom(null);
          setAgeTo(null);
          setEducationId(null);
          setPage(0);
          setIncomeId(null);
        }}
        selectedGender={selectedGender}
        setSelectedGender={setSelectedGender}
        ageFrom={ageFrom}
        ageTo={ageTo}
        setAgeFrom={setAgeFrom}
        setAgeTo={setAgeTo}
        setfamilyIncomeId={setfamilyIncomeId}
        familyIncome={masterDataList?.familyIncome}
      />
    </>
  );
}
