import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { EnquiryLabels } from "../../../schemes/enquiries/enquiries_list";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { CloseOutlined, DeleteOutline, Done } from "@mui/icons-material";
import { ServiceList } from "../../model/service_model";
import useUser from "../../../../providers/user/user_provider";

interface DataGridInterface {
  labels: EnquiryLabels[];
  data: ServiceList[];
  count: number;
  page: number;
  setPage: (val: number) => void;
  setSelectedUserId: (val: ServiceList) => void;
  setCloseRejectServiceStatusDialog: (val: boolean) => void;
  setCloseDeleteServiceStatusDialog: (val: boolean) => void;
  updateServiceApiCall: ({
    statusId,
    serviceId,
  }: {
    statusId: number;
    serviceId: number;
  }) => void;
}

export default function DatGridServices({
  labels,
  data,
  page,
  setPage,
  count,
  setSelectedUserId,
  updateServiceApiCall,
  setCloseRejectServiceStatusDialog,
  setCloseDeleteServiceStatusDialog,
}: DataGridInterface) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(parseInt(event.target.value, 10));
  };

  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;

  const actionComponent = ({
    status,
    actionOneDisable,
    actionTwoDisable,
    selectedItem,
  }: {
    status: string;
    actionOneDisable?: boolean;
    actionTwoDisable?: boolean;
    selectedItem: ServiceList;
  }) => {
    switch (status) {
      case "Pending":
      case "Modified":
      case "Submitted":
      case "In Progress":
        return (
          hasPermission("services", "update") ? <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUserId(selectedItem);
                updateServiceApiCall({
                  statusId: 3,
                  serviceId: +selectedItem?.id,
                });
              }}
              sx={{
                backgroundColor: "#E9F0ED",
                borderRadius: 3,
                color: "#1E6446",
                opacity: 1,
              }}
            >
              <Done />
            </IconButton>

            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUserId(selectedItem);
                setCloseRejectServiceStatusDialog(true);
              }}
              sx={{
                backgroundColor: "#FAEDEA",
                borderRadius: 3,
                ml: 2,
                color: "#D14C2E",
                opacity: 1,
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Box> : <></>
        );
      case "Rejected":
      case "Approved":
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            {hasPermission("services", "update") &&
              <IconButton
                sx={{
                  backgroundColor: "#E9F0ED",
                  borderRadius: 3,
                  color: "#000000",
                  opacity: actionOneDisable ? 0.4 : 1,
                }}
              >
                <Done />
              </IconButton>}

            {hasPermission("services", "delete") && <IconButton
              onClick={(e) => {
                e.stopPropagation();
                if (!actionTwoDisable) {
                  setSelectedUserId(selectedItem);
                  setCloseDeleteServiceStatusDialog(true);
                }
              }}
              sx={{
                backgroundColor: "#FAEDEA",
                borderRadius: 3,
                ml: 2,
                color: "#D14C2E",
                opacity: actionTwoDisable ? 0.4 : 1,
              }}
            >
              <DeleteOutline></DeleteOutline>
            </IconButton>}
          </Box>
        );
      default:
        break;
    }
  };

  function gridTypography({ text }: { text: string }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }

  function statusColorCodeBasedOnStatus({ status }: { status: string }) {
    if (
      status === "Modified" ||
      status === "Pending" ||
      status === "In Progress"
    ) {
      return "#91278F";
    } else if (status === "Approved") {
      return "#1E6446";
    } else {
      return "#D14C2E";
    }
  }

  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid container direction="row" p={2}>
          {labels.map((item, index) => (
            <Grid item flex={index === labels?.length - 1 ? 1.2 : 1}>
              <Typography color="#91278F" fontSize={14}>
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data.map((item, index) => {
              let statusColorCode = statusColorCodeBasedOnStatus({
                status: item?.status,
              });

              return (
                <Box
                  key={index}
                  onClick={(e) => {
                    navigate(`/services/serviceDetails/${item.id}`);
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    ":hover": {
                      // backgroundColor: "#FAEDEA",
                    },
                    cursor: "pointer",
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                  >
                    {gridTypography({ text: item?.id?.toString() })}
                    {gridTypography({ text: item?.name })}
                    {gridTypography({ text: item?.category })}
                    {gridTypography({ text: item?.state })}
                    {gridTypography({ text: item?.city })}
                    {gridTypography({ text: item?.district })}
                    {gridTypography({ text: `+91 ${item?.mobileNumber}` })}
                    {gridTypography({ text: item?.serviceEmailId })}
                    <Typography
                      display="flex"
                      flex={1}
                      fontSize={14}
                      fontWeight="600"
                      color={statusColorCode}
                    >
                      {item?.status}
                    </Typography>

                    <Box display="flex" flex={1.2} alignItems={"center"}>
                      {actionComponent({
                        status: item.status,
                        actionOneDisable:
                          item.status === "Approved" ||
                            item.status === "Rejected"
                            ? true
                            : false,
                        actionTwoDisable:
                          item.status === "Rejected" ? true : false,
                        selectedItem: item,
                      })}
                      <Box>
                        <img src={ArrowRight} alt="" height={15} width={15} />
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pl: 3,
            pr: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            labelRowsPerPage={customText}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={8}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <Grid md={6} sm={6} xs={6} lg={6} xl={6} mt={2} mb={4}>
          <Box onClick={() => navigate("/reports")} ml={2}>
            <Typography
              variant="button"
              color="#91278F"
              sx={{
                borderBottom: "1px solid #990099",
                cursor: "pointer",
                textTransform: "capitalize",
                fontWeight: "600",
              }}
            >
              Back To Report
            </Typography>
          </Box>
        </Grid>
      </CardActions>
    </Box>
  );
}
