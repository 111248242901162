import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import { ArrowBack, Download, DownloadOutlined, PlayCircle } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import XlsIcon from "../../../assets/xls_icon.svg";
import {
  downloadAudioVdoOfSubmittedChoice,
  downloadSubmittedResponse,
  getSubmitteSurveyordDetails,
} from "../../../services/surveyor/surveyor";
import { useQuery } from "react-query";
import { SubmittedChoiceDetails } from "../model/submitted_choice_details";
import { downloadFromBlob } from "../../../utils/utils";
import SurveyMedia from "../../../components/survey_media/survey_media";
import ReactPlayer from "react-player";

export default function SubmittedResponseDetails() {
  const [currentTab, setCurrentTab] = React.useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [image, setImage] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [src, setSrc] = useState("")


  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }

  const { data: surveyResponseDetails, isLoading } =
    useQuery<SubmittedChoiceDetails>(["surveyResponseDetails"], async () => {
      let response = await getSubmitteSurveyordDetails({
        choiceId: +state.id!,
        submittedId: +id!,
      });
      return response?.data?.data?.data;
    });

  const downloadSubmittedResponseApiCall = () => {
    downloadSubmittedResponse({
      choiceId: +state.id!,
      submittedId: +id!,
      format: "excel",
    })
      .then((res) => {
        let extension = ".xlsx";
        downloadFromBlob(res, extension);
      })
      .catch((e) => { });
  };

  const renderCompoBasedOnTheTab = () => {
    switch (currentTab) {
      case 0:
        return personalDetails();
      case 1:
        return locationDetails();
      case 2:
        return otherDetails();
      case 3:
        return durableDetails();
      case 4:
        return identificationOfRespondence();
    }
  };

  const handlePlay = () => {
    const audio = new Audio(audioUrl!);

    audio.play();
  };

  function personalDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "UID",
            value: surveyResponseDetails?.surveyorDetails?.id,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Full Name",
            value: surveyResponseDetails?.userDetails?.name,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Date of Birth",
            value: surveyResponseDetails?.userDetails?.dob,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Gender",
            value: surveyResponseDetails?.userDetails?.gender,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Marital Status  (Optional)",
            value: surveyResponseDetails?.userDetails?.maritalStatus,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Email  (Optional)",
            value: surveyResponseDetails?.userDetails?.email,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Mobile Numer",
            value: surveyResponseDetails?.userDetails?.mobileNumber,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}></Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Income",
            value: surveyResponseDetails?.userDetails?.familyIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Subscribed to Newsletter",
            value: "",
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Newsletter Email ID",
            value: "",
          })}
        </Grid>
      </>
    );
  }
  function locationDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "House/Flat/Room No. (Optional)",
            value: surveyResponseDetails?.locationDetails?.addressLine1,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Building/Chawl Name/No. (Optional)",
            value: surveyResponseDetails?.locationDetails?.addressLine2,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Street/Road No./Name (Optional)",
            value: surveyResponseDetails?.locationDetails?.addressLine3,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Locality/Area",
            value: surveyResponseDetails?.locationDetails?.area,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Landmark (Optional)",
            value: surveyResponseDetails?.locationDetails?.landmark,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "State",
            value: surveyResponseDetails?.locationDetails?.state,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "District",
            value: surveyResponseDetails?.locationDetails?.district,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "PIN Code",
            value: surveyResponseDetails?.locationDetails?.pincode,
          })}
        </Grid>
      </>
    );
  }

  function otherDetails() {
    return (
      <>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Educational Qualification",
            value: surveyResponseDetails?.otherDetails?.education,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Working Status",
            value: surveyResponseDetails?.otherDetails?.workingStatus,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Occupation",
            value: surveyResponseDetails?.otherDetails?.occupation,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Type",
            value: surveyResponseDetails?.otherDetails?.familtyType,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Ownership of House",
            value: surveyResponseDetails?.otherDetails?.houseOwnership,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Personal Income",
            value: surveyResponseDetails?.otherDetails?.personalIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}>
          {listItem({
            title: "Family Income",
            value: surveyResponseDetails?.otherDetails?.familyIncome,
          })}
        </Grid>
        <Grid item md={6} lg={6} sm={12} xs={12}></Grid>
      </>
    );
  }

  function durableDetails() {
    return (
      <Grid item md={6} lg={6} sm={12} xs={12}>
        <Typography color="primary.main">{"Durable details"}</Typography>
        {surveyResponseDetails?.durableDetails?.length === 0 ? (
          <Typography>{"-"}</Typography>
        ) : (
          surveyResponseDetails?.durableDetails?.map((durableItems) => (
            <Typography>{durableItems}</Typography>
          ))
        )}
      </Grid>
    );
  }

  useEffect(() => {
    downloadAudioVideo("audio", '.mp4', false)
  }, [])

  function downloadAudioVideo(format: string, extension: string = '.xlsx', audioDownload = false) {
    downloadAudioVdoOfSubmittedChoice({
      submittedId: +id!,
      type: format,
      choiceId: +state.id!,
    })
      .then((res) => {
        if (format === "image") {
          var urlCreator = window.URL || window.webkitURL;
          let imageUrl = urlCreator.createObjectURL(res.data);
          setImage(imageUrl);
        } else {
          if (audioDownload) {
            console.log(res, extension, 'res, extension');

            downloadFromBlob(res, extension);
          }
          setAudioUrl(res?.data);
          const audioBlob = new Blob([res.data], { type: 'video/mp4' });
          console.log(audioBlob, 'audio');

          const audioUrl = URL.createObjectURL(audioBlob);

          const url = URL.createObjectURL(res?.data);
          setSrc(audioUrl) //setting the url in your state. A hook in this case btw

        }
      })
      .catch((e) => { });
  }

  useEffect(() => {
    if (surveyResponseDetails?.otherDetails?.respondentImage)
      downloadAudioVideo("image");
  }, [surveyResponseDetails]);

  function identificationOfRespondence() {
    return (
      <Grid item md={12} lg={12} sm={12} xs={12}>
        {surveyResponseDetails?.otherDetails?.respondentImage && (
          <Box>
            <Typography color="primary.main" mb={1}>
              {"Respondent image"}
            </Typography>
            <img src={image} alt="" height={160} width={312} />
          </Box>
        )}
        <Grid container spacing={3}>
          {surveyResponseDetails?.otherDetails?.audioRecording && (
            <>
              <Grid item md={6} lg={6} sm={12} >
                <Typography color="primary.main">{"Respondent Audio"}</Typography>

                <Box
                  display={"flex"}
                  mt={1}
                  sx={{
                    border: "1px solid #F5E6F5",
                    borderRadius: 2,
                    p: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    downloadAudioVideo("audio", ".mp4", true);
                  }}
                  width="100%"
                >
                  <Typography flex={1}>{"Audio"}</Typography>
                  <DownloadOutlined
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      color: "primary.main",
                      alignItems: "flex-end",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                {src &&
                  <video controls height={90} width={330} >
                    <source src={src!} type="audio/mp4" />
                  </video>}
              </Grid>
            </>
          )}


          {surveyResponseDetails?.otherDetails?.videoRecording && (
            <Grid item md={6} lg={6} sm={12}>
              <Typography color="primary.main">{"Respondent Video"}</Typography>
              <Box
                display={"flex"}
                mt={1}
                sx={{
                  border: "1px solid #F5E6F5",
                  borderRadius: 2,
                  p: 2,
                  cursor: "pointer",
                }}
                onClick={() => {
                  downloadAudioVideo("video", '.mp4', true);
                }}
                width="100%"
              >
                <Typography flex={1}>{"Video"}</Typography>
                <DownloadOutlined
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "primary.main",
                    alignItems: "flex-end",
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  function answerType({ type, response }: { type: string; response: any }) {
    switch (type) {
      case null:
        return (
          (typeof response === "string" || typeof response === "boolean") && (
            <Box>
              <Typography>{response?.toString()}</Typography>
            </Box>
          )
        );

      case "text":
      case "comment":
      case "radiogroup":
      case "rating":
      case "checkbox":
      case "dropdown":
      case "tagbox":
      case "ranking":
      case "boolean":
      case "imagepicker":
        return (
          <Box>
            <Typography>{response?.toString()}</Typography>
          </Box>
        );
      case "multipletext":
        const objectValuesArray = Object.values(response);
        return (
          <Box>
            {objectValuesArray.map((value: any, index: any) => (
              <Typography>{value}</Typography>
            ))}
          </Box>
        );
      case "matrix":
        return (
          <Box>
            {Object.keys(response).map((qsn) => {
              return (
                <Typography>{`${qsn} : ${response[`${qsn}`]}`}</Typography>
              );
            })}
            <Typography></Typography>
          </Box>
        );
      case "matrixdropdown":
        return (
          <Box>
            {Object.keys(response).map((qsn) => {
              return (
                <Box display={"flex"}>
                  <Typography>{`${qsn} : `}</Typography>
                  {Object.keys(response[qsn]).map((item) => (
                    <Typography mx={1}>
                      {` ${item} -> ${response[qsn][item]}`}
                    </Typography>
                  ))}
                </Box>
              );
            })}
            <Typography></Typography>
          </Box>
        );
      case "matrixdynamic":
        return (
          <Box>
            {response.map((item: any) => {
              return Object.keys(item).map((qsnKey: any) => {
                return (
                  <Typography>{`${qsnKey} : ${item[`${qsnKey}`]}`}</Typography>
                );
              });
            })}
            <Typography></Typography>
          </Box>
        );
      case "file":
        return (
          <Box>
            {response.map((item: any) => {
              return (
                <Box py={1}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">{item.name}</Typography>
                    &nbsp;&nbsp;{" "}
                    <a href={item.content} download style={{ textDecoration: 'none' }} target="_blank">
                      <IconButton size="small">
                        <Download />
                      </IconButton>
                    </a>
                  </Box>

                  {/* <SurveyMedia url={item.content} type={item.type} /> */}
                </Box>
              );
            })}
            <Typography></Typography>
          </Box>
        );

    }
  }

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={8} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    Choice Response
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Grid container spacing={3} mt={1}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                  {listItem({
                    title: "Choice taken by",
                    value: surveyResponseDetails?.surveyorDetails?.name,
                  })}
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                  {listItem({
                    title: "Location",
                    value: `${surveyResponseDetails?.locationDetails.lat},${surveyResponseDetails?.locationDetails.lon}`,
                  })}
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                  <Box>
                    <Typography color="primary.main">
                      {"Choice Name"}
                    </Typography>
                    <Button
                      sx={{ mx: 0, px: 0 }}
                      onClick={() =>
                        navigate(
                          `/choices/viewChoices/${surveyResponseDetails?.choiceDetails?.choiceId}`
                        )
                      }
                    >
                      <Typography
                        color="primary.main"
                        fontWeight={"600"}
                        sx={{
                          borderBottom: "3px solid #990099",
                          width: "fit-content",
                        }}
                      >
                        {surveyResponseDetails?.choiceDetails.choiceName}
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                {surveyResponseDetails?.locationDetails.lat &&
                  surveyResponseDetails?.locationDetails.lon && (
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                      <Box
                        height={160}
                        mt={1}
                        sx={{ borderRadius: 4, overflow: "hidden" }}
                      >
                        <iframe
                          title="map"
                          width="100%"
                          height="100%"
                          frameBorder={0}
                          src={`https://maps.google.com/maps?q=${surveyResponseDetails?.locationDetails.lat},${surveyResponseDetails?.locationDetails.lon}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                        />
                      </Box>
                    </Grid>
                  )}
                <Box mt={2} mb={2}>
                  <Divider />
                </Box>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  paddingLeft={1}
                  ml={2}
                  mt={3}
                >
                  Respondent Details
                </Typography>
              </Grid>

              <Tabs
                value={currentTab}
                onChange={handleChange}
                sx={{
                  "MuiTabs-root css-112qdbf-MuiTabs-root": {
                    borderColor: "#F5E6F5",
                    overflow: 'auto'
                  },
                  "MuiTabs-flexContainer css-k008qs": {
                    overflow: 'scroll'
                  },
                  ".css-heg063-MuiTabs-flexContainer": {
                    overflow: 'auto'
                  },
                  mt: 2,
                }}
              >
                <Tab
                  label="Personal details"
                  sx={{
                    textTransform: "capitalize",
                    color: "#1B001B",
                  }}
                />
                <Tab
                  label="Location details"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
                <Tab
                  label="Other details"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
                <Tab
                  label="Durable details"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
                <Tab
                  label="Identification of respodence"
                  sx={{ textTransform: "capitalize", color: "#1B001B" }}
                />
              </Tabs>
              <Box mt={3}>
                <Grid container spacing={3} mt={1}>
                  {renderCompoBasedOnTheTab()}
                </Grid>
              </Box>

              <Box mt={2}>
                <Typography variant="subtitle1" fontWeight={"600"}>
                  Question and Answers
                </Typography>
              </Box>
              <Box>
                <Card
                  sx={{
                    height: 350,
                    overflowY: "scroll",
                    p: 2,
                    borderRadius: 5,

                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#F5E6F5",
                      borderRadius: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#DFB0DF",
                      borderRadius: "6px",
                    },
                  }}
                >
                  {surveyResponseDetails?.choiceDetails?.answerList?.map(
                    (items, index: any) => {
                      return (
                        items.response && (
                          <Box mb={1}>
                            <Typography color="#91278F" variant="body2">
                              {index + 1}. {items?.question}
                            </Typography>
                            {answerType({
                              type: items.questionType,
                              response: items.response,
                            })}
                          </Box>
                        )
                      );
                    }
                  )}
                </Card>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  mt={2}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle2">Generate Report: </Typography>
                  {IconComponent(XlsIcon, "#FAEDEA", () => {
                    downloadSubmittedResponseApiCall();
                  })}
                </Box>
                <Button
                  variant="outlined"
                  sx={{
                    pl: 2,
                    pr: 2,
                    textTransform: "capitalize",
                    fontWeight: 600,
                    minWidth: 200,
                    mt: 3,
                  }}
                  onClick={() => navigate(-1)}
                >
                  Back To Responses
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout >
  );
}
