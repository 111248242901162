import {
  Box,
  Button,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { CloseOutlined, DeleteOutline, Done, Edit, EditOutlined, KeyboardArrowRight } from "@mui/icons-material";
import useUser from "../../../../providers/user/user_provider";
import { Need } from "../../model/need";
import { orange } from "../../../../theme/colors";

interface DataGridInterface {
  labels: { label: string }[];
  data: Need[];
  count: number;
  page: number;
  setPage: ({ pageSize, page }: { pageSize: number, page: number }) => void;
  selectedNeedsIds: number[];
  setSelectedNeedIds: (val: number[]) => void;
  setOpenPopup: (val: boolean) => void;
  selectedStatus: number | null;
}

export default function DatGrid({
  labels,
  data,
  page,
  setPage,
  count,
  selectedNeedsIds,
  setSelectedNeedIds,
  setOpenPopup,
  selectedStatus
}: DataGridInterface) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage({ pageSize: 8, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {

  };
  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;


  function gridTypography({ text }: { text: string | number }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }


  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid container direction="row" p={2} alignItems={'center'}>
          {(data?.length !== 0 && hasPermission("needs", "update")) &&
            <Checkbox
              disabled={selectedStatus === 3}
              checked={selectedNeedsIds?.length === data.length}
              onChange={() => {
                if (selectedNeedsIds?.length !== data.length) {
                  setSelectedNeedIds(data?.map((i) => i.needId))
                } else {
                  setSelectedNeedIds([])
                }
              }
              } sx={{ p: 0, mr: 2 }} />}
          {labels.map((item, index) => (
            <Grid
              item
              flex={index === labels?.length - 1 ? 1.5 : 1}
            >
              <Typography color="#91278F" fontSize={14}>
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data.map((item, index) => {
              return (
                <Box
                  key={index}
                  onClick={(e) => {
                    navigate(`/needs/${item.needId}`);
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    ":hover": {
                    },
                    cursor: "pointer",
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                    alignItems={'center'}
                  >
                    {hasPermission("needs", "update") && <Checkbox
                      disabled={item?.status === 'Closed'}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (selectedNeedsIds?.includes(item?.needId)) {
                          setSelectedNeedIds(selectedNeedsIds?.filter((i) => i !== item.needId))
                        } else {
                          setSelectedNeedIds([...selectedNeedsIds, item?.needId]);
                        }
                      }} checked={selectedNeedsIds.includes(item?.needId)} sx={{ p: 0, mr: 2 }} />}
                    {gridTypography({ text: item?.needId })}
                    {gridTypography({ text: item?.name })}
                    {gridTypography({ text: item?.mobileNumber })}
                    {gridTypography({ text: item?.category })}
                    {gridTypography({ text: item?.subCategory })}
                    {gridTypography({ text: item?.brand })}
                    {gridTypography({ text: item?.area })}
                    {gridTypography({ text: item?.state })}
                    {gridTypography({ text: item?.city })}
                    <Box
                      display="flex"
                      flex={1.5}
                      style={{
                        overflow: "hidden",
                      }}
                      alignItems={'center'}
                    >
                      <Typography fontWeight={600} color={item.status === 'Closed' ?
                        'success.main' : item?.status === 'In Progress'
                          ?
                          orange : 'text.primary'
                      } fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
                        {item?.status ? item?.status : "-"}
                      </Typography>
                      <EditOutlined
                        sx={{ width: 18, height: 18, ml: 1 }}
                      />
                      <Box display={'flex'} justifyContent={'flex-end'} flex={1}>
                        <IconButton>
                          <KeyboardArrowRight
                            sx={{ color: "#D14C2E" }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box mt={1} display={'flex'} width='100%' justifyContent={'flex-end'} >
          {selectedNeedsIds.length !== 0 && <Button variant='contained' sx={{ minWidth: 200 }}
            onClick={() => setOpenPopup(true)}>
            Change status
          </Button>}
        </Box>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pl: 3,
            pr: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            labelRowsPerPage={customText}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={8}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </CardActions>
    </Box>
  );
}
