import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { RoleList } from "../../model/admin_roles";

interface Props {
  deleteRole: boolean;
  selectedRoleDetails: RoleList;
  deleteAdminRolesApiCall: () => void;
  setDeleteRole: (val: boolean) => void;
}

export default function DeleteRole({
  deleteRole,
  selectedRoleDetails,
  deleteAdminRolesApiCall,
  setDeleteRole,
}: Props) {
  const Dialog = styled(MuiDialog)(({ theme }) => {
    return {
      ".css-1o50bed-MuiPaper-root-MuiDialog-paper": {
        borderRadius: 16,
      },
    };
  });

  return (
    <Dialog open={deleteRole} sx={{ borderRadius: 16 }}>
      <DialogContent style={{ minWidth: "500px", borderRadius: 20 }}>
        <Typography variant="body1" fontWeight={600} textAlign={"center"} mt={3}>
          Are you sure you want to delete <br /> this Role?
        </Typography>
        <Typography variant="body1" fontWeight={600} textAlign={"center"} mt={3}>
          {selectedRoleDetails.role}
        </Typography>



        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-around"}
          mt={3}
        >
          <Button
            onClick={() => {
              setDeleteRole(false);
            }}
            variant="text"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              deleteAdminRolesApiCall?.();
            }}
            sx={{ minWidth: 200 }}
            variant="contained"
          >
            Delete
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
