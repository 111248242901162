import {
  ArrowBack,
  DeleteOutline,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField as MuiTextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../user_management/user_management";
import Loading from "../../../../components/loading/loading";
import useUser from "../../../../providers/user/user_provider";
const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function UserListingPage() {
  const {
    setViewAdmin,
    setDeleteAdmin,
    adminList,
    setSelectedAdminDetails,
    isLoading,
    searchList,
    searchValue,
    setSearchValue,
  } = useMyContext();
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  return (
    <Box display={"flex"}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            width: "100%",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: 2,
            m: 1,
            minHeight: 550,
          }}
          elevation={1}
        >
          <CardContent sx={{ flexGrow: 1, height: "100%" }}>
            <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
              <Box flexDirection="row" display="flex" alignItems="center">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack></ArrowBack>
                </IconButton>
                <Typography variant="h6" fontWeight="600">
                  Admins
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mt: 2,
                  height: "100%",
                }}
              >
                <Grid container>
                  <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    py={1}
                    pl={1}
                    width="100%"
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      container
                    >
                      <Grid md={4} sm={6} xs={8} lg={4} xl={4}>
                        <TextField
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          size="small"
                          fullWidth
                          sx={{
                            backgroundColor: "#F5E6F5",
                            borderRadius: 3,
                            borderColor: "transparent",
                          }}
                          placeholder="Search"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search sx={{ color: "text.primary" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid md={2} sm={4} xs={6} lg={2} xl={2}>
                        <Button
                          onClick={() => navigate("/admins/addNewAdmin")}
                          fullWidth
                          color="primary"
                          variant="contained"
                          disabled={!hasPermission("users", "create")}
                        >
                          Add New Admin
                        </Button>
                      </Grid>
                    </Grid>
                    {searchList?.length === 0 ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        flex={1}
                      >
                        <Typography>No Data Available</Typography>
                      </Box>
                    ) : (
                      <Box display={"flex"} mt={3} flexDirection={"column"}>
                        {searchList?.map((userInfo, index) => {
                          return (
                            <>
                              <ListItem
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  my: 2,
                                }}
                              >
                                <ListItemText
                                  primary={userInfo?.name}
                                  secondary={userInfo?.role_name}
                                  secondaryTypographyProps={{
                                    color: "#91278F",
                                  }}
                                  primaryTypographyProps={{
                                    fontWeight: 600,
                                    color: userInfo.userActive
                                      ? "#1B001B"
                                      : "grey",
                                  }}
                                />
                                <ListSubheader sx={{ margin: 0, padding: 0 }}>
                                  <IconButton
                                    onClick={() => {
                                      if (userInfo?.userActive) {
                                        setViewAdmin(true);
                                        setSelectedAdminDetails?.(userInfo);
                                      }
                                    }}
                                    sx={{
                                      backgroundColor: "#E9F0ED",
                                      borderRadius: 3,
                                      color: "#1B001B",
                                      opacity: userInfo?.userActive ? 1 : 0.4,
                                    }}
                                  >
                                    <Visibility></Visibility>
                                  </IconButton>
                                  {/* <IconButton
                                    onClick={() => {
                                      if (userInfo?.userActive)
                                        navigate("/admins/modifyAdminDetails");
                                    }}
                                    sx={{
                                      backgroundColor: "#F5E6F5",
                                      borderRadius: 4,
                                      ml: 2,
                                      color: "#1B001B",
                                      opacity: userInfo?.userActive ? 1 : 0.4,
                                    }}
                                  >
                                    <Edit></Edit>
                                  </IconButton> */}

                                  {hasPermission("users", "delete") && (
                                    <IconButton
                                      onClick={() => {
                                        if (userInfo?.userActive) {
                                          setDeleteAdmin(true);
                                          setSelectedAdminDetails?.(userInfo);
                                        }
                                      }}
                                      sx={{
                                        backgroundColor: "#F5E6F5",
                                        borderRadius: 3,
                                        ml: 2,
                                        color: "#000000",
                                        opacity: userInfo?.userActive ? 1 : 0.4,
                                      }}
                                    >
                                      <DeleteOutline></DeleteOutline>
                                    </IconButton>
                                  )}
                                </ListSubheader>
                              </ListItem>
                              {index !== adminList?.length - 1 && (
                                <Divider
                                  sx={{
                                    display: "flex",
                                    backgroundColor: "#7C797C",
                                    opacity: 0.1,
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>

          <CardActions>
            <Grid container>
              <Grid
                display="flex"
                width={"100%"}
                flexDirection="row"
                justifyContent="space-between"
                m={2}
              >
                <Grid md={2} sm={4} xs={4} lg={2} xl={2}>
                  <Button
                    onClick={() => navigate("/reports", { replace: true })}
                    fullWidth
                    sx={{
                      border: "1px solid #91278F",
                      borderRadius: 2,
                      fontWeight: 600,
                      textTransform: "capitalize",
                      color: "#000000",
                    }}
                  >
                    Back To Reports
                  </Button>
                </Grid>
                <Grid md={2} sm={4} xs={4} lg={3} xl={2}>
                  <Box>
                    <Button
                      variant="contained"
                      disabled={!hasPermission("users", "read")}
                      onClick={() => {
                        navigate("/admins/manageRolesAndAccess");
                      }}
                      fullWidth
                      color={"primary"}
                    >
                      Manage Roles and Access
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      )}
    </Box>
  );
}
