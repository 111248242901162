import { createBrowserRouter } from "react-router-dom";

import { Auth, Authorize, Choices, Dashboard, Needs } from "../views";
import AccessDisabled from "../views/access_disabled/access_disabled";
import AddNewAdmin from "../views/admins/components/add_new_admin/add_new_admin";
import UserManagement from "../views/admins/user_management/user_management";
import Analytics from "../views/analytics/analytics";
import Enquiries from "../views/schemes/enquiries/enquiries_list";
import NeedDetails from "../views/needs/details/details";
import NotFound from "../views/not_found/not_found";
import Settings from "../views/settings/settings";
import SurveyUI from "../views/survey-ui/survey-ui";
import AddNewSchemes from "../views/schemes/add_new_scheme/add_new_scheme";
import EnquiryDetails from "../views/schemes/enquiry_details/enquiry_details";
import FamilyEnquiries from "../views/schemes/family_enquiries/family_enquiry_list";
import FamilyEnquiryDetails from "../views/schemes/family_enquiry_details/family_enquiry_details";
import ModifyAdminDetails from "../views/admins/user_management/modify_user_details/modify_user_details";
import ModifyRolePermissions from "../views/admins/role_management/modify_role_permissions/modify_role_permissions";
import Schemes from "../views/schemes/schemes/schemes";
import EditScheme from "../views/schemes/edit_scheme/edit_scheme";
import Reports from "../views/reports/reports";
import ChoicesResponse from "../views/choices/choices_response/choices_response";
import Services from "../views/services/services";
import ServiceDetails from "../views/services/service_details/service_details";
import Jobs from "../views/jobs/jobs";
import JobDetails from "../views/jobs/job_details";
import JobApplicationDetails from "../views/jobs/job_application_details/job_application_details";
import CreateNewChoices from "../views/choices/create_new_choices/create_new_choices";
import ViewChoices from "../views/choices/edit_choice/view_choice";
import ResponseDetailPage from "../views/choices/response_detail_page/response_detail_page";
import FamilyResponseDetails from "../views/choices/family_response_details/family_response_Details";
import Surveyor from "../views/surveyor/surveyor";
import CreateNewSurveyor from "../views/surveyor/create_surveyor/create_new_surveyor";
import ViewSurveyor from "../views/surveyor/view_surveyor/view_surveyor";
import SubmittedResponseDetails from "../views/surveyor/submitted_response_Details/submitted_response_details";
import SurveyorChoiceResponseList from "../views/surveyor/components/surveyor_response_list/surveyor_response_list";
import Users from "../views/users/users";
import UserDetails from "../views/users/usersDetails/userDetails";
import Rewards from "../views/rewards/rewards";
import RewardsDetails from "../views/rewards/rewards_details/rewards_details";
import PrivateRoute from "./private_route";
import FileUpload from "../views/file_upload/file_upload";
import { add_new_client, add_roles, client_details, client_mapping, create_user, file_uploads, manage_roles_and_access, modify_client, modify_role, modify_user, view_role } from "./routes_path";
import CreateUser from "../views/users/create_user/create_user";
import ModifyUser from "../views/users/modify_user/modify_user";
import ClientMapping from "../views/client_mapping/client_mapping/user_management";
import ClientDetails from "../views/client_mapping/components/client_details/client_details";
import RoleManagement from "../views/client_mapping/role_management/role_management";
import AddNewClient from "../views/client_mapping/components/add_new_admin/add_new_client";
import AddAdminRole from "../views/client_mapping/components/add_admin_role/add_admin_role";
import ModifyClient from "../views/client_mapping/components/modify_client/modify_client";
import Loading from "../views/loading/loading";

export const routes = [
  {
    path: "/",
    element: <Auth />,
    title: "Auth",
  },
  {
    path: "/authorize",
    element: <Authorize />,
    title: "Authorize",
  },
  {
    path: "/dashboard",
    element: <PrivateRoute module='dashboard' path="/dashboard" element={<Dashboard />} />,
    title: "",
  },

  {
    path: "/reports",
    element: <PrivateRoute module='reports' path="/reports" element={<Reports />} />,
    title: "",
  },
  {
    path: "/needs",
    element: <PrivateRoute module='needs' path="/needs" element={<Needs />} />,
    title: "Needs",
  },
  {
    path: "/needs/:id",
    element: <PrivateRoute module='needs' path="/needs" element={<NeedDetails />} />,
    title: "Need Details",
  },
  {
    path: "/admins",
    element: <PrivateRoute module='reports' path="/reports" element={<UserManagement />} />,
    title: "User Management",
  },
  {
    path: "/admins/addNewAdmin",
    element: <PrivateRoute module='reports' path="/reports" element={<AddNewAdmin />} />,
    title: "Add New Admin",
  },
  {
    path: "/admins/modifyAdminDetails",
    element: <PrivateRoute module='reports' path="/reports" element={<ModifyAdminDetails />} />,
    title: "Modify Admin details",
  },
  {
    path: "/admins/manageRolesAndAccess",
    element: <PrivateRoute module='reports' path="/reports" element={<RoleManagement />} />,
    title: "Manage Roles And Access",
  },
  {
    path: "/admins/manageRolesAndAccess/modifyRole",
    element: <PrivateRoute module='reports' path="/reports" element={<ModifyRolePermissions viewOnly={false} />} />,
    title: "Modify Admin Role",
  },
  {
    path: "/admins/manageRolesAndAccess/viewRoleDetails",
    element: <PrivateRoute module='reports' path="/reports" element={<ModifyRolePermissions viewOnly={true} />} />,
    title: "Modify Admin Role",
  },
  {
    path: "*",
    element: <NotFound />,
    title: "NotFound",
  },
  {
    path: "/schemes/add",
    element: <PrivateRoute module='schemes' path="/schemes" element={<AddNewSchemes />} />,
    title: "Add New Scheme",
  },
  {
    path: "/analytics",
    element: <PrivateRoute module='reports' path="/reports" element={<Analytics />} />,
    title: "Analytics",
  },
  {
    path: "/choices",
    element: <PrivateRoute module='choices' path="/choices" element={<Choices />} />,
    title: "Choices",
  },
  {
    path: "/choices/viewChoice/:id",
    element: <PrivateRoute module='choices' path="/choices" element={<ChoicesResponse />} />,
    title: "Choices",
  },
  {
    path: "/settings",
    element: <PrivateRoute module='reports' path="/reports" element={<Settings />} />,
    title: "Settings",
  },
  {
    path: "/access-disabled",
    element: <PrivateRoute module='access-disabled' path="/access-disabled" element={<AccessDisabled />} />,
    title: "Settings",
  },
  {
    path: "/survey-ui/:id",
    element: <PrivateRoute module='choices' path="/survey-ui/:id" element={<SurveyUI />} />,
    title: "Settings",
  },
  {
    path: "/schemes",
    element: <PrivateRoute module='schemes' path="/schemes" element={<Schemes />} />,
    title: "Schemes",
  },
  {
    path: "/schemes/edit/:id",
    element: <PrivateRoute module='schemes' path="/schemes" element={<EditScheme readOnly={false} />} />,
    title: "Edit",
  },
  {
    path: "/schemes/:id",
    element: <PrivateRoute module='schemes' path="/schemes" element={<EditScheme readOnly={true} />} />,
    title: "Edit",
  },
  {
    path: "/schemes/enquiries",
    element: <PrivateRoute module='schemes' path="/schemes" element={<Enquiries />} />,
    title: "Enquiries",
  },
  {
    path: "/schemes/enquiries/:id",
    element: <PrivateRoute module='schemes' path="/schemes" element={<EnquiryDetails />} />,
    title: "Enquiry Details",
  },
  {
    path: "/schemes",
    element: <PrivateRoute module='schemes' path="/schemes" element={<Schemes />} />,
    title: "Schemes",
  },
  {
    path: "/schemes/familyEnquiries",
    element: <PrivateRoute module='schemes' path="/schemes" element={<FamilyEnquiries />} />,
    title: "Family Enquiries",
  },
  {
    path: "/schemes/familyEnquiries/:id",
    element: <PrivateRoute module='schemes' path="/schemes" element={<FamilyEnquiryDetails />} />,
    title: "Family Enquiry Details",
  },
  {
    path: "/choices/responses/",
    element: <PrivateRoute module='choices' path="/choices" element={<ChoicesResponse />} />,
    title: "Choices Response",
  },
  {
    path: "/choices/viewResponse/:id",
    element: <PrivateRoute module='choices' path="/choices" element={<ResponseDetailPage />} />,
    title: "Choices Response",
  },
  {
    path: "/choices/familyResponseDetails/:id",
    element: <PrivateRoute module='choices' path="/choices" element={<FamilyResponseDetails />} />,
    title: "Choices Response",
  },
  {
    path: "/choices/createNewChoice/",
    element: <PrivateRoute module='choices' path="/choices" element={<CreateNewChoices />} />,
    title: "Create new choices",
  },
  {
    path: "/services",
    element: <PrivateRoute module='services' path="/services" element={<Services />} />,
    title: "Services",
  },
  {
    path: "/services/serviceDetails/:id",
    element: <PrivateRoute module='services' path="/service" element={<ServiceDetails />} />,
    title: "Services",
  },
  {
    path: "/jobs",
    element: <PrivateRoute module='jobs' path="/jobs" element={<Jobs />} />,
    title: "Jobs",
  },
  {
    path: "/jobs/jobsDetails/:id",
    element: <PrivateRoute module='jobs' path="/jobs" element={<JobDetails />} />,
    title: "Jobs Listing",
  },
  {
    path: "/jobs/jobsApplicationDetails/:id",
    element: <PrivateRoute module='jobs' path="/jobs" element={<JobApplicationDetails />} />,
    title: "Jobs Listing",
  },
  {
    path: "/choices/editChoice/:id",
    element: <PrivateRoute module='choices' path="/choices" element={<ViewChoices editChoice={true} />} />,
    title: "Edit Choice",
  },
  {
    path: "/choices/viewChoices/:id",
    element: <PrivateRoute module='reports' path="/reports" element={<ViewChoices viewOnly={true} />} />,
    title: "View Choice",
  },
  {
    path: "/surveyor",
    element: <PrivateRoute module='reports' path="/reports" element={<Surveyor />} />,
    title: "Surveyor",
  },
  {
    path: "/surveyor/createNewSurveyor",
    element: <PrivateRoute module='reports' path="/reports" element={<CreateNewSurveyor />} />,
    title: "Create New Surveyor",
  },
  {
    path: "/surveyor/modifySurveyorDetails",
    element: <PrivateRoute module='reports' path="/reports" element={<CreateNewSurveyor modifyFlow={true} />} />,
    title: "Modify Surveyor details",
  },
  {
    path: "/surveyor/viewSurveyorDetails/:id",
    element: <PrivateRoute module='reports' path="/reports" element={<ViewSurveyor />} />,
    title: "View Surveyor Details",
  },
  {
    path: "/surveyor/viewSubmittedDetails/:id",
    element: <PrivateRoute module='reports' path="/reports" element={<SubmittedResponseDetails />} />,
    title: "View Surveyor Details",
  },
  {
    path: "/surveyor/viewChoices/:id",
    element: <PrivateRoute module='reports' path="/reports" element={<SurveyorChoiceResponseList />} />,
    title: "View Surveyor Details",
  },
  {
    path: "/users",
    element: <PrivateRoute module='reports' path="/reports" element={<Users />} />,
    title: "Users",
  },
  {
    path: "/users/userDetails/:id",
    element: <PrivateRoute module='reports' path="/reports" element={<UserDetails />} />,
    title: "User Details",
  },
  {
    path: "/rewards",
    element: <PrivateRoute module='reports' path="/reports" element={<Rewards />} />,
    title: "Rewards",
  },
  {
    path: "/rewards/rewardsDetails/:id",
    element: <PrivateRoute module='reports' path="/reports" element={<RewardsDetails />} />,
    title: "Rewards Details",
  },
  {
    path: file_uploads,
    element: <PrivateRoute module='reports' path="/reports" element={<FileUpload />} />,
    title: "Bulk Processing",
  },
  {
    path: create_user,
    element: <PrivateRoute module='reports' path="/reports" element={<CreateUser />} />,
    title: "Create User",
  },
  {
    path: `${modify_user}/:id`,
    element: <PrivateRoute module='reports' path="/reports" element={<ModifyUser />} />,
    title: "Modify User",
  },
  {
    path: client_mapping,
    element: <PrivateRoute module='reports' path="/reports" element={<ClientMapping />} />,
    title: "Client Mapping",
  },
  {
    path: `${client_details}/:id`,
    element: <PrivateRoute module='reports' path="/reports" element={<ClientDetails />} />,
    title: "Client Details",
  },
  {
    path: add_new_client,
    element: <PrivateRoute module='reports' path="/reports" element={<AddNewClient modifyFlow={false} />} />,
    title: "Add New Client",
  },
  {
    path: add_new_client,
    element: <PrivateRoute module='reports' path="/reports" element={<AddNewClient modifyFlow={true} />} />,
    title: "Add New Client",
  },
  {
    path: `${modify_client}/:id`,
    element: <PrivateRoute module='reports' path="/reports" element={<ModifyClient />} />,
    title: "Modify Client",
  },
  {
    path: manage_roles_and_access,
    element: <PrivateRoute module='reports' path="/reports" element={<RoleManagement />} />,
    title: 'Manage Access and Roles'
  },
  {
    path: add_roles,
    element: <PrivateRoute module='reports' path="/reports" element={<AddAdminRole modifyRole={false} />} />,
    title: 'Add Roles'
  },
  {
    path: `${modify_role}/:id`,
    element: <PrivateRoute module='reports' path="/reports" element={<AddAdminRole modifyRole={true} />} />,
    title: 'Modify Roles'
  },
  {
    path: `${view_role}/:id`,
    element: <PrivateRoute module='reports' path="/reports" element={<AddAdminRole modifyRole={false} viewRole={true} />} />,
    title: 'View Roles'
  },

  {
    path: '/loading',
    element: <Loading />,
    title: 'Loading'
  },

];

export default createBrowserRouter(routes);
