import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import SurveyorList from "./components/surveyorList/surveyor_list";
import SurveyorLayout from "./components/layout/surveyor_layout";
import ChoicesListing from "./components/surveyor_choices_listing/surveyor_choices_listing";
import useUser from "../../providers/user/user_provider";
import { Box } from "@mui/material";
import AccessDenied from "../../components/access_denied/access_denied";
import { useSearchParams } from "react-router-dom";

export default function Surveyor() {
  const [searchParams, setUrlParam] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(
    searchParams.has("currentTab") ? +searchParams?.get("currentTab")! : 0
  );

  const { hasPermission } = useUser();
  function renderItemBasedOnTheTabSelection() {
    switch (currentTab) {
      case 1:
        return <ChoicesListing />;
      case 0:
        return <SurveyorList />;
      default:
        break;
    }
  }

  useEffect(() => {
    setUrlParam({ currentTab: currentTab ? currentTab?.toString() : "0" });
  }, [currentTab]);


  return (
    <MainLayout>
      {hasPermission("surveyors", "read") ? (
        <SurveyorLayout currentTab={currentTab} setCurrentTab={setCurrentTab}>
          {renderItemBasedOnTheTabSelection()}
        </SurveyorLayout>
      ) : (
        <Box display="flex" flex={1} width="100%" height="100%">
          <AccessDenied />
        </Box>
      )}
    </MainLayout>
  );
}
