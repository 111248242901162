import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import XLSIcon from "../../assets/ph_file-xls.svg";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loading/loading";
import useUser from "../../providers/user/user_provider";
import { JobListDetails } from "./model/job_details_model";
import {
  deleteJob,
  downloadJobDetails,
  getJobDetails,
  updateJobStatus,
} from "../../services/jobs/jobs";
import { useQuery } from "react-query";
import UpdateJobsStatusToReject from "./components/update_jobs_status/update_jobs_status_popup";
import UpdateJobsStatusToDelete from "./components/update_jobs_status/update_jobs_status_popup";
import { useFeedback } from "../../providers/feedback/feeedback";
import { queryClient } from "../../providers/query/query";
import { downloadFromBlob } from "../../utils/utils";

export default function JobDetails() {
  const [serviceStatus, setServiceStatus] = useState<string | null>(null);
  const [closeRejectJobStatusDialog, setCloseRejectJobStatusDialog] =
    useState<boolean>(false);
  const [closeDeleteJobStatusDialog, setCloseDeleteJobStatusDialog] =
    useState<boolean>(false);

  let { id } = useParams();
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const { showSnackbar } = useFeedback();

  const { data: jobDetails, isLoading } = useQuery<JobListDetails>(
    ["job-application-details", id],
    async () => {
      let data = await getJobDetails({ jobId: +id! });
      return data.data.data;
    }
  );

  function deleteJobApiCall({ comments }: { comments: string }) {
    deleteJob({ jobId: +id!, comment: comments })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Job removed successfully");
          navigate("/jobs");
        }
      })
      .catch((e) => {
        if (
          e.response.status === 403
        ) {
          showSnackbar("You do not have permission to perform this operation.");
        } else {
          showSnackbar("Something went wrong");
        }
      });
  }

  function updateJobStatusApiCall(comments: string, statusId: number) {
    updateJobStatus({
      comment: comments,
      statusId: statusId,
      jobId: +id!,
    })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Job status updated successfully");
          queryClient.invalidateQueries({
            queryKey: ["job-application-details"],
          });
        }
      })
      .catch((e) => {
        if (
          e.response.status === 403
        ) {
          showSnackbar("You do not have permission to perform this operation.");
        } else {
          showSnackbar("Something went wrong");
        }
      });
  }

  function downloadExcel() {
    downloadJobDetails(jobDetails?.id!, "excel").then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".xlsx");
    });
  }

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }
  function loading() {
    return (
      <Box
        height={500}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }

  useEffect(() => {
    setServiceStatus(jobDetails?.jobStatus!);
  }, [jobDetails]);

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={7} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box width={200} display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    Job Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {isLoading ? (
                  loading()
                ) : (
                  <Box overflow="auto" flex={1}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        {listItem({
                          title: "Sl. No.",
                          value: jobDetails?.id,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job Type",
                          value: jobDetails?.jobType,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        <Box>
                          <Typography color="primary.main">
                            {"Educational Qualification"}
                          </Typography>
                          {jobDetails?.education.map((edu) => {
                            return <Typography>{edu}</Typography>;
                          })}
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        <Box>
                          <Typography color="primary.main">
                            {"Occupation"}
                          </Typography>
                          {jobDetails?.occupation.map((occ) => {
                            return <Typography>{occ}</Typography>;
                          })}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job Role / Position",
                          value: jobDetails?.jobPosition,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "Name of Company / Person",
                          value: jobDetails?.company,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "City",
                          value: jobDetails?.city,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "District",
                          value: jobDetails?.district,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "State",
                          value: jobDetails?.state,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Exp. Required",
                          value: jobDetails?.workExperience,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job Contact Number",
                          value: jobDetails?.jobContactNo,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job Contact Email ",
                          value: jobDetails?.jobEmailId,
                        })}
                      </Grid>
                    </Grid>
                    <Box mt={2} mb={2}>
                      <Divider />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography>Generate Report:</Typography>
                      <IconButton sx={{ ml: 2 }} onClick={downloadExcel}>
                        <img src={XLSIcon} alt="" />
                      </IconButton>
                    </Box>
                    {jobDetails?.remarks && (
                      <Grid item md={6} mt={2} mb={4}>
                        {listItem({
                          title: "Reason for reject",
                          value: jobDetails?.remarks,
                        })}
                      </Grid>
                    )}
                    <Grid item md={12}>
                      <Box display="flex" justifyContent="space-between">
                        <Button
                          variant="outlined"
                          sx={{
                            pl: 2,
                            pr: 2,
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                          onClick={() => navigate(-1)}
                        >
                          Back To Jobs
                        </Button>
                        <Box>
                          {jobDetails?.jobStatus === "Closed" ||
                            jobDetails?.jobStatus === "Rejected" ||
                            jobDetails?.jobStatus === "Approved" ||
                            !hasPermission("jobs", "update") ? null : (
                            <Button
                              // disabled={!hasPermission("jobs", "update")}
                              variant="outlined"
                              sx={{
                                pl: 4,
                                pr: 4,
                                textTransform: "capitalize",
                                fontWeight: 600,
                              }}
                              onClick={() => {
                                setCloseRejectJobStatusDialog(true);
                              }}
                            >
                              Reject Listing
                            </Button>
                          )}
                          {jobDetails?.jobStatus === "Rejected" ||
                            jobDetails?.jobStatus === "Closed" ? (
                            <Button
                              onClick={() => null}
                              variant="contained"
                              sx={{
                                minWidth: 150,
                                opacity: 0.5,
                                backgroundColor: "#D14C2E",
                              }}
                            >
                              {jobDetails?.jobStatus}
                            </Button>
                          ) : (
                            hasPermission("jobs", "update") || hasPermission("jobs", "delete") ?
                              <Button
                                // disabled={
                                //   !jobDetails?.jobStatus === "Approved"
                                //   // ? !hasPermission("jobs", "delete")
                                //   // : !hasPermission("jobs", "update")
                                // }
                                variant="contained"
                                sx={{
                                  pl: 4,
                                  pr: 4,
                                  fontWeight: 600,
                                  ml: 2,
                                  backgroundColor:
                                    jobDetails?.jobStatus === "Modified" ||
                                      jobDetails?.jobStatus === "In Progress" ||
                                      jobDetails?.jobStatus === "Pending"
                                      ? "#1E6446"
                                      : "#91278F",
                                }}
                                onClick={() => {
                                  if (jobDetails?.jobStatus === "Approved") {
                                    setCloseDeleteJobStatusDialog(true);
                                  } else {
                                    updateJobStatusApiCall("", 3);
                                  }
                                }}
                              >
                                {jobDetails?.jobStatus === "Approved"
                                  ? "Delete Listing"
                                  : "Approve Listing"}
                              </Button> : null
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {closeRejectJobStatusDialog && (
        <UpdateJobsStatusToReject
          open={closeRejectJobStatusDialog}
          onDialogClose={() => {
            setCloseRejectJobStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            updateJobStatusApiCall(comments, 4);
            setCloseRejectJobStatusDialog(false);
          }}
          title={"Reject Job"}
          subtitle={"Reason for rejection:"}
        />
      )}
      {closeDeleteJobStatusDialog && (
        <UpdateJobsStatusToDelete
          open={closeDeleteJobStatusDialog}
          onDialogClose={() => {
            setCloseDeleteJobStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            deleteJobApiCall({ comments: comments });
            setCloseDeleteJobStatusDialog(false);
          }}
          title={"Delete Job"}
          subtitle={"Reason for Deletion:"}
        />
      )}
    </MainLayout>
  );
}
