import { IconButton, Radio, TextField, Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import { Attachment, Close } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function UpdateNeedStatus({
  open,
  onDialogClose,
  updateNeedStatusToClosed,
}: {
  open: boolean;
  onDialogClose: () => void;
  updateNeedStatusToClosed: (remarks: string, file: File | null, notificationMode: string[]) => void;
}) {
  const [comments, setComments] = React.useState("");
  const [error, setError] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const { showSnackbar } = useFeedback()
  const [notificationSentMode, setNotificationSentMode] = React.useState<string[]>([]);

  function submit() {
    setError("");
    if (comments.length === 0) {
      setError("Enter comments");
    } else {
      updateNeedStatusToClosed(comments, selectedFile, notificationSentMode);
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const maxFileSize = 10 * 1024 * 1024;
    if (file != null) {
      if (file.size > maxFileSize) {
        showSnackbar("File size exceeds 10 MB");
      } else {
        setSelectedFile(file)
      }

    }
  };

  const handleRadioChange = (val: string) => {
    if (notificationSentMode.includes(val)) {
      let filteredData = notificationSentMode.filter((i) => i !== val)
      setNotificationSentMode(filteredData)
    } else {
      setNotificationSentMode([...notificationSentMode, val])
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onDialogClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box pb={2}>
            <Typography
              id="transition-modal-title"
              align="center"
              variant="body1"
              fontSize={18}
              fontWeight={600}
            >
              Are you sure you want to mark this needs as closed?
            </Typography>
          </Box>
          <Box position={"relative"}>
            <TextField
              multiline
              minRows="9"
              fullWidth
              value={comments}
              onChange={({ target: { value } }) => {
                setComments(value);
              }}
              error={Boolean(error)}
              helperText={error}
              label="Closing Feedback"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 300,
              }}
            ></TextField>
            <Typography
              variant="caption"
              sx={{ position: "absolute", bottom: 8, left: 12 }}
            >{`${300 - comments.length} characters left`}</Typography>
          </Box>

          {!selectedFile ?
            <>
              <Box mt={2} p={1} sx={{ border: '1px solid #F5E6F5', borderRadius: 2 }}>
                <label htmlFor="fileUpload" className="custom-file-upload">
                  <IconButton component="span">
                    <Attachment sx={{ color: '#7C797C' }} />
                  </IconButton>
                </label>
                <input
                  className="custom-file-upload"
                  id="fileUpload"
                  onChange={handleFileChange}
                  type="file"
                  style={{ display: 'none' }}
                  accept=".png, .jpg, .jpeg, .mp4, .mp3"
                />
                <label htmlFor="fileUpload" className="custom-file-upload">
                  <Button component="span" sx={{ color: "#7C797C" }}>Click here to upload image or video or audio</Button>
                </label>

              </Box>
              <Box mt={1}>
                <Typography variant="caption">
                  Upload a .png, .jpeg, jpeg, .mp4 and .mp3 document with maximum size of 10 MB
                </Typography>
              </Box>
            </>
            :
            <Box display={'flex'} flexDirection={'row'} mt={1}>
              <Typography mt={1}>{selectedFile?.name}</Typography>
              <IconButton onClick={() => setSelectedFile(null)}>
                <Close />
              </IconButton>
            </Box>}

          <Box mt={2}>
            <Typography variant="subtitle1" fontWeight={'600'} color={'primary.main'}>
              Notification option
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection={'row'} mt={0}>
            <Box display={'flex'} flexDirection={'row'}>
              <Checkbox
                checked={notificationSentMode.includes('sms')}
                value={notificationSentMode} name={'sms'}
                onChange={() => handleRadioChange('sms')}
              />
              <Typography variant="subtitle1"
                fontWeight={'600'}
                mt={1}>
                SMS
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'} ml={2}>
              <Checkbox
                checked={notificationSentMode.includes('whatsapp')}
                value={notificationSentMode}
                name={'whatsapp'}
                onChange={() => handleRadioChange('whatsapp')}
              />
              <Typography variant="subtitle1" fontWeight={'600'} mt={1}>
                Whatsapp
              </Typography>
            </Box>
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Box onClick={onDialogClose}>
              <Typography
                variant="button"
                color="#91278F"
                sx={{
                  borderBottom: "1px solid #990099",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontWeight: "600",
                }}
              >
                Close
              </Typography>
            </Box>
            <Button variant="contained" onClick={submit} disabled={!comments}>
              Mark as closed
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
