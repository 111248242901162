export const create_choice = "/choices/createNewChoice/";
export const file_uploads = '/fileUplods';
export const create_user = '/users/createUser';
export const modify_user = '/users/modifyUser';
export const client_mapping = '/clientMapping'
export const client_details = '/clientMapping/clientDetails';
export const add_new_client = '/clientMapping/addNewClient';
export const modify_client = '/clientMapping/modifyClient';
export const manage_roles_and_access = '/clientMapping/manageRolesAndAccess';
export const add_roles = '/clientMapping/manageRolesAndAccess/addRole';
export const modify_role = '/clientMapping/manageRolesAndAccess/ModifyRole';
export const view_role = '/clientMapping/manageRolesAndAccess/ViewRole';
export const clientPermissions = 'v1/admin/users/v2/users/permissions'
export const details = 'v1/casbin/users'