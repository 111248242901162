import { details } from "../../routes/routes_path";
import HttpClient from "../../utils/http";
import { add_admin, create_roles, get_individual_client_details, get_roles, get_roles_details, list_clients } from "../constants/api_endpoints";

export interface PermissionFilter {
    category_id?: (number | null)[];
    subcategory_id?: (number | null)[];
    brand_id?: number[] | null;
    areas_ids?: number[] | null;
    state_ids?: (number | null)[];
    city_ids?: (number | null)[];
    scheme_ids?: number[];
    choice_ids?: number[];
    client_email?: string;
  }
  
  export interface ClientPermission {
    module: string;
    actions: string[];
    filters: PermissionFilter;
  } 
  

export function createRoles({name, superAdmin,permissions}:
    {name:string, superAdmin:boolean, permissions:ClientPermission[]|[]}){
    return HttpClient.post(create_roles, {
        name:name,
        superAdmin: superAdmin,
        permissions: permissions
      })
}

export function updateRoles({name, superAdmin,permissions, roleId}:
  {name:string, superAdmin:boolean, permissions:ClientPermission[]|[], roleId:number}){
  return HttpClient.patch(`${create_roles}/${roleId}`, {
      name:name,
      superAdmin: superAdmin,
      permissions: permissions
    })
}

export function getRoles({ page,size, module, keyword}:{
  page: number;
  size: number;
  module:string, keyword:string}){

    let url = get_roles + `?size=${size}&page=${page}&module=${module}`;
     if(keyword)
    url+=`&keyword=${keyword}`
    return HttpClient.get(url)
}

export function getRolesDetails({ roleId}:{roleId: number}){
  let url = get_roles_details + `/${roleId}`;
  return HttpClient.get(url)
}

export function addClient({ clientName,clientEmailId, moduleId}:
  {clientName: string;clientEmailId: string;moduleId:number}){
  return HttpClient.post(add_admin,{
    name:clientName,
    emailId: clientEmailId,
    roleId: moduleId
  })
}

export function modifyClient({ clientName,clientEmailId, moduleId, clientId}:
  {clientName: string;clientEmailId: string;moduleId:number, clientId:number}){
  return HttpClient.patch(`${add_admin}?id=${clientId}`,{
    name:clientName,
    emailId: clientEmailId,
    roleId: moduleId
  })
}

export function updateClient({  clientId}:
  {clientId:number}){
  return HttpClient.patch(`${add_admin}?id=${clientId}`)
}

export function getClient({ page,size, module, keyword}:{page: number;size: number;module:string, keyword:string}){
  
  
  let url = list_clients + `?page=${page}&size=${size}&module=${module}`;
  if(keyword)
    url+=`&keyword=${keyword}`
  return HttpClient.get(url)
}

export function getClientDetails({id}:{id:number}){
  let url = `${details}/id`;
  return HttpClient.get(url)
}

export function deleteClient({clientId}:{clientId:number}){
  return HttpClient.delete(`${add_admin}?id=${clientId}`)
}

export function deleteClientRole({roleId}:{roleId:number}){
  let url = get_roles + `?roleId=${roleId}`;
  return HttpClient.delete(url)
}

export function getIndividualClientDetails({ id}:{id:number}){
  let url = get_individual_client_details + `/${id}`;
  return HttpClient.get(url)
}