import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMyContext } from "../user_management";
import { RoleList } from "../../model/admin_roles";

export default function ViewUserDetails() {
  const { viewAdmin, setViewAdmin, selectedAdminDetails, roleList } =
    useMyContext();

  const [roles, setRoles] = useState<RoleList | null>(null);

  const Dialog = styled(MuiDialog)(({ theme }) => {
    return {
      ".css-1o50bed-MuiPaper-root-MuiDialog-paper": {
        borderRadius: 16,
      },
    };
  });

  function getUserAssignedRoleDetails() {
    roleList?.map?.((role) => {
      if (role.id === selectedAdminDetails.roleId) {
        setRoles(role);
      }
    });
  }

  useEffect(() => {
    getUserAssignedRoleDetails();
  }, [viewAdmin]);

  return (
    <Dialog open={viewAdmin}>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", minWidth: "400px" }}
      >
        <ListItem>
          <ListItemText
            primary="Admin Details"
            primaryTypographyProps={{ fontWeight: "800" }}
          />
          <ListSubheader sx={{ margin: 0, padding: 0 }}>
            <IconButton
              onClick={() => setViewAdmin(false)}
              sx={{
                color: "#323232",
              }}
            >
              <Close />
            </IconButton>
          </ListSubheader>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <ListItemText
            primary="Admin name"
            secondary={selectedAdminDetails?.name}
            primaryTypographyProps={{
              fontSize: 14,
              color: "#91278F",
            }}
            secondaryTypographyProps={{
              fontSize: 18,
            }}
          />
          <ListItemText
            primary="Email id"
            secondary={selectedAdminDetails.email_id}
            primaryTypographyProps={{
              fontSize: 14,
              color: "#91278F",
            }}
            secondaryTypographyProps={{
              fontSize: 18,
            }}
          />
          <ListItemText
            primary="Role"
            secondary={
              selectedAdminDetails.role_name !== ""
                ? selectedAdminDetails.role_name
                : "-"
            }
            primaryTypographyProps={{
              fontSize: 14,
              color: "#91278F",
            }}
            secondaryTypographyProps={{
              fontSize: 18,
            }}
          />
          <ListItemText
            primary="Access"
            primaryTypographyProps={{
              fontSize: 14,
              color: "#91278F",
            }}
          />

          {roles?.features.map((features) => {
            return (
              <Box display="flex" flexDirection={"row"}>
                <Typography fontWeight={"600"}>
                  {features.name}
                  {" -"}
                </Typography>
                <Box display="flex" flexDirection={"row"}>
                  {features?.permissions.map((permission) => {
                    return (
                      <Typography ml={1}>
                        {permission.name}
                        {","}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            width={"100%"}
            mt={3}
          >
            <Button variant="text" onClick={() => setViewAdmin(false)}>
              Cancel
            </Button>
            {/* <Button
              onClick={() => navigate("/admins/modifyAdminDetails")}
              variant="contained"
              sx={{
                minWidth: 200,
                minHeight: 45,
                color: "#ffffff",
              }}
            >
              Modify
            </Button> */}
          </Box>
        </ListItem>
      </DialogContent>
    </Dialog>
  );
}
