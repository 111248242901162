import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import locales from "../../../constants/locales";
import MainLayout from "../../../layouts/main/main_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { queryClient } from "../../../providers/query/query";
import {
  getScheme,
  updateSchemeDetails,
  updateSchemeEligibility,
} from "../../../services/schemes/schemes";
import { SchemeDetailsResponse } from "../models/scheme_details";
import {
  SchemeEligibilityInputs,
  SchemeOtherDetailsInputs,
} from "../models/scheme_form";
import { SchemeProvider } from "../providers/scheme_provider";
import SchemeDetails from "./components/scheme_details";
import SchemeEligilibility from "./components/scheme_eligilibility";

export default function EditScheme({
  readOnly = false,
}: {
  readOnly: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [eligibilityEdit, setEligibilityEdit] = useState(false);
  const { showSnackbar } = useFeedback();
  const [selectedLocale, setSelectedLocale] = useState("en");
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isError } = useQuery<SchemeDetailsResponse>(
    ["scheme-details", id!, selectedLocale],
    async () => {
      let data = await getScheme(id!, selectedLocale);
      return data.data.data;
    },
    {
      retry: 2,
    }
  );

  async function saveSchemeDetails(data: SchemeOtherDetailsInputs) {
    setIsLoading(true);
    updateSchemeDetails(id!, selectedLocale, data).then((res) => {
      showSnackbar("Saved.");
      setIsLoading(false);
    }).catch((e => {
      if (
        e.response.status === 403
      ) {
        showSnackbar("You do not have permission to perform this operation.");
      } else {
        showSnackbar("Something went wrong.");
      }
    }));


  }

  async function updateEligibility(eligibility: SchemeEligibilityInputs) {
    setEligibilityEdit(false);
    setIsLoading(true);
    updateSchemeEligibility(id!, eligibility).then((res) => {
      showSnackbar("Saved.");
      setIsLoading(false);
      queryClient.invalidateQueries({
        queryKey: ["scheme-details", id!, selectedLocale],
      });
    }).catch((e => {
      if (
        e.response.status === 403
      ) {
        showSnackbar("You do not have permission to perform this operation.");
      } else {
        showSnackbar("Something went wrong.");
      }
    }));

  }

  return (
    <MainLayout>
      <SchemeProvider>
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12} lg={12} xl={10}>
            {/* {hasPermission("Schemes", "Read") ? ( */}
            <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
              <CardContent>
                <Box display="flex" flexDirection="row">
                  <Box width={400} display="flex" alignItems="center">
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBack></ArrowBack>
                    </IconButton>
                    <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                      {readOnly ? "View scheme" : "Edit scheme"}
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                  ></Box>
                </Box>
                <Tabs
                  value={locales.findIndex(
                    (locale) => locale.code === selectedLocale
                  )}
                  onChange={(e, index: number) =>
                    setSelectedLocale(locales[index].code)
                  }
                >
                  {locales.map((locale, index) => (
                    <Tab
                      label={locale.name}
                      sx={{
                        textTransform: "capitalize",
                        maxWidth: 50,
                      }}
                    />
                  ))}
                </Tabs>
                <Divider />

                <SchemeDetails
                  isError={isError}
                  readOnly={readOnly}
                  openEligibility={() => {
                    setEligibilityEdit(true);
                  }}
                  data={data}
                  onSave={saveSchemeDetails}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {eligibilityEdit && (
          <SchemeEligilibility
            isOpen={eligibilityEdit}
            onClose={() => {
              setEligibilityEdit(false);
            }}
            data={data}
            onSave={(data: SchemeEligibilityInputs) => {
              updateEligibility(data);
            }}
          />
        )}
      </SchemeProvider>
    </MainLayout>
  );
}
