import React, { useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/loading/loading";
import { useQuery } from "react-query";
import { SurveyorDetails } from "../model/surveyor_details";
import {
  getDetailsOfSurveyor,
  getDetailsOfSurveyorChoices,
  removeSurveyor,
} from "../../../services/surveyor/surveyor";
import DeleteSuryevorPopup from "../components/delete_surveyor_popup/delete_surveyor_popup";
import { useFeedback } from "../../../providers/feedback/feeedback";
import useUser from "../../../providers/user/user_provider";
export default function ViewSurveyor() {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  let { id } = useParams();
  const { showSnackbar } = useFeedback();
  const { hasPermission } = useUser();

  const { data: surveyorDetails, isLoading } = useQuery<SurveyorDetails>(
    ["surveyor-Details"],
    async () => {
      let response = await getDetailsOfSurveyor({ id: +id! });
      return response?.data?.data;
    }
  );

  const { data: surveyorChoiceDetails } = useQuery(
    ["surveyorChoiceDetails"],
    async () => {
      let response = await getDetailsOfSurveyorChoices({
        id: +id!,
        page: 1,
        size: 8,
      });
      return response?.data?.data || [];
    }
  );

  function deleteSurveyor() {
    removeSurveyor({ id: +id! })
      .then((res) => {
        showSnackbar("Surveyor deleted successfully");
        navigate("/surveyor");
      })
      .catch((e) => showSnackbar("Something went wrong"));
    setOpenDeletePopup(false);
  }

  const navigate = useNavigate();
  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }

  function loading() {
    return (
      <Box
        height={300}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }
  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={8} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    View Surveyor Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {isLoading ? (
                  loading()
                ) : (
                  <Box overflow="auto" flex={1}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        {listItem({
                          title: "Agents Name",
                          value: surveyorDetails?.name,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Mobile Number",
                          value: surveyorDetails?.mobileNumber,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Agent Location",
                          value: surveyorDetails?.location,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "State",
                          value: surveyorDetails?.state,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "City",
                          value: surveyorDetails?.city,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "District",
                          value: surveyorDetails?.district,
                        })}
                      </Grid>
                    </Grid>
                    <Box mt={4}>
                      <Typography variant="body1" fontWeight={"600"}>
                        Choices mapped
                      </Typography>
                    </Box>
                    {surveyorChoiceDetails?.list?.map(
                      (choice: {
                        choiceId: number;
                        questions: number;
                        name: string;
                        languages: string;
                        validFrom: string;
                        validTo: string;
                        id: number;
                      }) => {
                        return (
                          <>
                            <ListItem
                              sx={{
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              <ListItemText
                                primary={choice?.name}
                                secondary={
                                  <Box display="flex" flexDirection="row">
                                    <Typography variant="body2">{`Questions:${choice.questions}`}</Typography>
                                    {/* <Box ml={3}>
                                                      <Typography variant="body2">{`Language:`}</Typography>
                                                      {surveyorsListData.list.map(
                                                        (item: string) => {
                                                          <Typography variant="body2" ml={3}>
                                                            {item}
                                                          </Typography>;
                                                        }
                                                      )}
                                                    </Box> */}
                                    <Typography
                                      variant="body2"
                                      ml={3}
                                    >{`Valid from:${choice.validFrom}`}</Typography>
                                    <Typography
                                      variant="body2"
                                      ml={3}
                                    >{`Valid to:${choice.validTo}`}</Typography>
                                  </Box>
                                }
                                primaryTypographyProps={{
                                  color: "#1B001B",
                                }}
                                secondaryTypographyProps={{
                                  color: "#91278F",
                                }}
                              />
                            </ListItem>
                          </>
                        );
                      }
                    )}
                    <Grid container mt={3}>
                      <Grid item md={6} lg={6} sm={12}>
                        <Button
                          variant="outlined"
                          sx={{
                            pl: 2,
                            pr: 2,
                            textTransform: "capitalize",
                            fontWeight: 600,
                            minWidth: 200,
                          }}
                          onClick={() => navigate(-1)}
                        >
                          Back To Surveyors
                        </Button>
                      </Grid>

                      <Grid item md={6} lg={6} sm={12}>
                        <Button
                          disabled={!hasPermission("surveyors", "delete")}
                          variant="outlined"
                          sx={{
                            pl: 4,
                            pr: 4,
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                          onClick={() => setOpenDeletePopup(true)}
                        >
                          Delete Surveyor
                        </Button>

                        <Button
                          disabled={!hasPermission("surveyors", "update")}
                          variant="contained"
                          sx={{
                            pl: 4,
                            pr: 4,
                            textTransform: "capitalize",
                            fontWeight: 600,

                            ml: 3,
                          }}
                          onClick={() => {
                            navigate("/surveyor/modifySurveyorDetails/", {
                              state: { id: +id! },
                            });
                          }}
                        >
                          Modify
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DeleteSuryevorPopup
        deleteSurveyor={deleteSurveyor}
        openPopup={openDeletePopup}
        setOpenPopup={setOpenDeletePopup}
      />
    </MainLayout>
  );
}
