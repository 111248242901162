import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import IncidentGroupingNeedsList from './incident_grouping_needs_list'
import IncidentGroupingLabelList from './incident_grouping_label_list'
import { useSearchParams } from 'react-router-dom'

export default function NeedsBucketing() {
    const [stepper, setStepper] = useState(1);
    const [selectedLabel, setSelectedLabel] = useState("");
    const [searchParams, setUrlParam] = useSearchParams();

    useEffect(() => {
        console.log(searchParams, 'searchParams');
        setUrlParam({ labelling: 'needList', }, { replace: true }); // "replace: true" prevents unwanted history entries
    }, [searchParams, setUrlParam, stepper]);

    return (
        <Box>
            <Box>
                {stepper === 1 && <IncidentGroupingLabelList
                    setStepper={setStepper}
                    setSelectedLabel={setSelectedLabel}
                />}
                {stepper === 2 &&
                    <IncidentGroupingNeedsList
                        selectedLabel={selectedLabel}
                    />}
            </Box>
        </Box>
    )
}
