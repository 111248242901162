import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BaseLayout from "../../layouts/base/base_layout";
import Loading from "../../components/loading/loading";
import { Box, Grid, Typography } from "@mui/material";
import Session from "../../utils/session";
import useUser from "../../providers/user/user_provider";

export default function Authorize() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { login, userPermissions, loading } = useUser();
  const { search, state } = useLocation();
  const queryparams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);
    if (
      queryparams &&
      queryparams.has("accessToken") &&
      queryparams.has("refreshToken") &&
      queryparams.get("accessToken") &&
      queryparams.get("refreshToken")
    ) {
      setLoading(true);
      Session.login(
        queryparams.get("accessToken")!,
        queryparams.get("refreshToken")!
      );
      login(queryparams.get("accessToken")!, queryparams.get("refreshToken")!);


    } else {
      navigate("/", { replace: true });
    }
  }, [queryparams, userPermissions, loading, navigate, login]);

  useEffect(() => {
    if (!loading && userPermissions) {
      let nav = "/" + `${userPermissions?.permissions?.[0]?.module}`;
      navigate(nav, { replace: true });
    }
  }, [loading, userPermissions, navigate]);

  return (
    <BaseLayout>
      <Box
        height={"100vh"}
        width="100vw"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box m={4}>
          <Loading />
        </Box>
        <Typography variant="body1">Authorizing...</Typography>
      </Box>
    </BaseLayout>
  );
}
