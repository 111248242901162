import React, { useEffect } from 'react'
import BaseLayout from '../../layouts/base/base_layout'
import { Box, Typography } from '@mui/material'
import useUser from '../../providers/user/user_provider';
import { useNavigate } from 'react-router-dom';

export default function Loading() {
    const { login, userPermissions, } = useUser();
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/loading', { replace: true });
        if (userPermissions != null) {
            let nav = "/" + `${userPermissions?.permissions?.[0]?.module}`
            navigate(nav, { replace: true });
        }
    }, [])

    return (
        <BaseLayout>
            <Box
                height={"100vh"}
                width="100vw"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box m={4}>
                    <Loading />
                </Box>
                <Typography variant="body1">Authorizing...</Typography>
            </Box>
        </BaseLayout>
    )
}
