import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import useUser from "../../../../providers/user/user_provider";
import { Sort, UnfoldMore } from "@mui/icons-material";

export default function DurationPopup({
    updatedStatus,
    onStatusChange,
    durationList
}: {
    updatedStatus: { id: number, duration: string } | null;
    onStatusChange: (status: { id: number, duration: string, days: number }) => void;
    durationList: { id: number, duration: string, days: number }[]
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { hasPermission } = useUser();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box ml={1}>
            <Button
                sx={{
                    color: "text.primary",
                    textTransform: "capitalize",
                    p: 0,
                    m: 0,
                }}
                id="basic-button"
                aria-controls={open ? "status-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}

            >
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="body1"
                        color={'#91278F'}
                        sx={{ textDecoration: 'underline' }}
                        mr={.5}
                    >
                        {updatedStatus?.duration}
                    </Typography>
                    <UnfoldMore sx={{ color: "#91278F" }} />

                </Box>
            </Button>
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{ elevation: 3 }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {durationList?.map((item) => {
                    return <MenuItem
                        onClick={() => {
                            onStatusChange(item);
                            handleClose();
                        }}
                    >
                        {item?.duration}
                    </MenuItem>
                })}

            </Menu>
        </Box>
    );
}
