import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../../layouts/main/main_layout";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import { postAdmin } from "../../../../services/admin/admin_services";
import { RoleList } from "../../model/admin_roles";
import { addClient, getIndividualClientDetails, getRoles, modifyClient } from "../../../../services/client_mapping/client_mapping";
import { useQuery } from "react-query";
import { ClientDetail } from "../../model/client_details";
import { client_mapping } from "../../../../routes/routes_path";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    ".css-18918z0-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "#1B001B",
    },
  };
});
export default function ModifyClient() {
  const navigate = useNavigate();
  const [adminName, setAdminName] = useState("");
  const [adminEmailId, setAdminEmailId] = useState("");
  const [adminRole, setAdminRole] = useState<{
    roleId: number;
    role: string;
  } | null>(null);
  const [error, setError] = useState(false);
  const { showSnackbar } = useFeedback();
  const { id } = useParams()


  const { data: roleList } = useQuery(
    ["get-Roles"],
    async () => {
      let response = await getRoles({ page: 1, size: 100, module: 'all', keyword: '' });
      return response.data.data;
    }
  );

  const { data: details } = useQuery<ClientDetail>(
    "modify-Client-Details",
    async () => {
      let { data } = await getIndividualClientDetails({ id: +id! });
      return data?.data;
    }
  );

  const modifyAdminApiCall = () => {
    const regex = /[^a-zA-Z0-9 ]/;
    if (regex.test(adminName)) {
      showSnackbar("Special characters are not allowed for admin name");
    } else {
      modifyClient({ clientEmailId: adminEmailId, clientName: adminName, moduleId: adminRole?.roleId!, clientId: +id! })
        .then((res) => {
          showSnackbar("Client Modified Successfully");
          navigate(client_mapping)
        })
        .catch((e) => {
          if (e.response.status === 445 && e.response.data.error.code === 445) {
            showSnackbar("Client already exist !");
          } else if (
            e.response.status === 403 &&
            e.response.data.error.code === 403
          ) {
            showSnackbar("Client don't have permission");
          } else if (e?.response?.data?.error?.message) {
            showSnackbar(e?.response?.data?.error?.message);
          } else {
            showSnackbar("Something went wrong");
          }
        });
    }
  };

  useEffect(() => {
    setAdminEmailId(details?.emailId || "");
    setAdminName(details?.clientName || "")
    setAdminRole({ roleId: details?.roleId!, role: details?.roleAssigned! })

  }, [details])

  useEffect(() => {
    console.log(adminRole, 'admin role');

  }, [adminRole])




  const submitForm = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (emailRegex?.test(adminEmailId)) {
      modifyAdminApiCall?.();
    } else {
      setError(true);
    }
  };

  return (
    <MainLayout>
      <Grid container spacing={3} mt={3}>
        <Grid item md={6} sm={12} xs={12} lg={6} xl={6}>
          <Card sx={{ width: "100%", borderRadius: 4, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="column" pl={1}>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    Modify Client
                  </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Box>
                    <TextField
                      fullWidth
                      label="Admin Name"
                      placeholder="Enter Admin name"
                      value={adminName}
                      onChange={(event) => {
                        if (event.target.value !== ' ')
                          setAdminName(event?.target.value);
                      }}
                      inputProps={{ maxLength: 40 }}
                      required
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      fullWidth
                      label="Email ID"
                      placeholder="Enter Email id"
                      value={adminEmailId}
                      onChange={(event) => {
                        if (event.target.value !== ' ') {
                          setAdminEmailId(event?.target.value.trim());
                        }
                        setError(false)
                      }}
                      required
                    />
                    {error && (
                      <FormHelperText sx={{ color: "red", m: 0 }}>
                        Please Enter valid Email
                      </FormHelperText>
                    )}
                  </Box>
                  <Box mt={3}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">
                        Select Role<span style={{ color: "#D14C2E" }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="role-label"
                        label="Select an Option"
                        fullWidth
                        placeholder="Select an Option"
                        value={adminRole?.role || ""}
                        onChange={(e) => {
                          const selectedRole = roleList?.list?.find(
                            (role: any) => role.role === e.target.value
                          );
                          if (selectedRole) {
                            setAdminRole({
                              roleId: selectedRole.id,
                              role: selectedRole.role,
                            });
                          }
                        }}
                      >
                        {roleList?.list?.map((role: RoleList) => (
                          <MenuItem key={role.id} value={role.role} className={role.role}>
                            {role.role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={4}
                  >
                    <Button
                      onClick={() => navigate(-1)}
                      sx={{
                        border: "1px solid #91278F",
                        borderRadius: 2,
                        fontWeight: 600,
                        textTransform: "capitalize",
                        minWidth: 200,
                        color: "#91278F",
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={submitForm}
                      disabled={
                        adminName && adminRole
                          ? false
                          : true
                      }
                      sx={{
                        minWidth: 200,
                      }}
                      variant="contained"
                    >
                      Modify Admin
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
