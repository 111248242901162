import { Box } from "@mui/material";
import MainLayout from "../../layouts/main/main_layout";
import useUser from "../../providers/user/user_provider";
import ChoicesListing from "./components/choices_listing_page/choices_listing";
import AccessDenied from "../../components/access_denied/access_denied";
import ChoicesLayout from "./components/layout/choices_layout";
import { useState } from "react";
import ChoicesResponseList from "./components/choices_response_list/choices_response_list";
import FamilyResponseList from "./components/family_response_list/family_response_list";

export default function Choices() {
  const [currentTab, setCurrentTab] = useState(0);
  const { hasPermission } = useUser();
  function renderItemBasedOnTheTabSelection() {
    switch (currentTab) {
      case 0:
        return <ChoicesListing />;
      case 1:
        return <ChoicesResponseList currentTab={currentTab} />;
      case 2:
        return <FamilyResponseList currentTab={currentTab} />;
      default:
        break;
    }
  }
  return (
    <MainLayout>
      {/* <SurveyJs /> */}
      {hasPermission("choices", "read") ? (
        <ChoicesListing />
      ) : (
        <Box display="flex" flex={1} width="100%" height="100%">
          <AccessDenied />
        </Box>
      )}
    </MainLayout>
  );
}
