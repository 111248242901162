import {
  ArrowBack,
  DeleteOutline,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField as MuiTextField,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../../client_mapping/user_management";
import Loading from "../../../../components/loading/loading";
import useUser from "../../../../providers/user/user_provider";
import { add_new_client, client_details, manage_roles_and_access, modify_client } from "../../../../routes/routes_path";
const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

interface ClientMappingInterface {
  currentTab: number;
  handleChange: (val: number) => void;
  page: number;
  setPage: (val: number) => void;
  count: number
}

export default function ClientListingPage({ currentTab, handleChange, page, count,
  setPage, }: ClientMappingInterface) {
  const {
    setViewAdmin,
    setDeleteAdmin,
    adminList,
    setSelectedAdminDetails,
    isLoading,
    searchList,
    searchValue,
    setSearchValue,
  } = useMyContext();
  const navigate = useNavigate();
  const { hasPermission, userPermissions } = useUser();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;

  return (
    <Box display={"flex"}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: 2,
          m: 1,
          minHeight: 550,
        }}
        elevation={1}
      >
        <CardContent sx={{ flexGrow: 1, height: "100%" }}>
          <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Box flexDirection="row" display="flex" alignItems="center">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack></ArrowBack>
                </IconButton>
                <Typography variant="h6" fontWeight="600">
                  Client Mapping
                </Typography>
              </Box>
              <Box>
                <Button
                  onClick={() => navigate(add_new_client)}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  Add New Client
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mt: 2,
                height: "100%",
              }}
            >
              <Grid container>
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  py={1}
                  pl={1}
                  width="100%"
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  xl={12}
                >
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12} lg={12} xl={2} alignItems={'flex-end'} justifyContent={'flex-end'}>

                    </Grid>
                  </Grid>
                  <Tabs
                    value={currentTab}
                    aria-label="basic tabs example"
                    onChange={(event: React.SyntheticEvent, currentTabVal: number) => handleChange(currentTabVal)}
                    sx={{
                      "MuiTabs-root css-112qdbf-MuiTabs-root": {
                        borderColor: "#F5E6F5",
                      },
                    }}
                  >
                    <Tab
                      label="All"
                      sx={{
                        textTransform: "capitalize",
                        color: "#1B001B",
                      }}
                    />
                    <Tab
                      label="Needs"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Schemes"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Choices"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Services"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                    <Tab
                      label="Jobs"
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                    />
                  </Tabs>

                  <Grid container mt={2}>
                    <Grid md={4} sm={6} xs={8} lg={4} xl={4}>
                      <TextField
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        size="small"
                        fullWidth
                        sx={{
                          backgroundColor: "#F5E6F5",
                          borderRadius: 3,
                          borderColor: "transparent",
                        }}
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search sx={{ color: "text.primary" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  {searchList?.length === 0 ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      flex={1}
                    >
                      <Typography>No Data Available</Typography>
                    </Box>
                  ) : (
                    isLoading ? <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                        width: "100%",
                      }}
                    >
                      <Loading />
                    </Box> :
                      <Box display={"flex"} mt={3} flexDirection={"column"}>
                        {searchList?.map((userInfo, index) => {
                          return (
                            <>
                              <ListItem
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  my: 2,
                                }}
                              >
                                <ListItemText
                                  primary={userInfo?.name}
                                  secondary={userInfo?.role_name}
                                  secondaryTypographyProps={{
                                    color: "#91278F",
                                  }}
                                  primaryTypographyProps={{
                                    fontWeight: 600,
                                    color: "#1B001B"
                                  }}
                                />
                                <ListSubheader sx={{ margin: 0, padding: 0 }}>
                                  <IconButton
                                    onClick={() => {
                                      navigate(`${client_details}/${userInfo.id}`)
                                    }}
                                    sx={{
                                      backgroundColor: "#E9F0ED",
                                      borderRadius: 3,
                                      color: "#1B001B",
                                    }}
                                  >
                                    <Visibility></Visibility>
                                  </IconButton>
                                  <IconButton
                                    disabled={userInfo?.isSuperAdmin}
                                    onClick={() => {
                                      navigate(`${modify_client}/${userInfo.id}`);
                                    }}
                                    sx={{
                                      backgroundColor: "#F5E6F5",
                                      borderRadius: 4,
                                      ml: 2,
                                      color: "#1B001B",
                                    }}
                                  >
                                    <Edit></Edit>
                                  </IconButton>


                                  <IconButton
                                    disabled={userInfo?.isSuperAdmin}
                                    onClick={() => {
                                      setDeleteAdmin(true);
                                      setSelectedAdminDetails?.(userInfo);
                                    }}
                                    sx={{
                                      backgroundColor: "#F5E6F5",
                                      borderRadius: 3,
                                      ml: 2,
                                      color: "#000000",
                                    }}
                                  >
                                    <DeleteOutline></DeleteOutline>
                                  </IconButton>

                                </ListSubheader>
                              </ListItem>
                              {index !== adminList?.length - 1 && (
                                <Divider
                                  sx={{
                                    display: "flex",
                                    backgroundColor: "#7C797C",
                                    opacity: 0.1,
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CardContent>


        <CardActions>
          <Box display={'flex'} flexDirection={'column'}
            width={"100%"} >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                pl: 3,
                pr: 2,
              }}
            >
              <Typography variant="body2">{customText}</Typography>
              <TablePagination
                component="div"
                labelRowsPerPage={customText}
                rowsPerPageOptions={[]}
                count={count}
                rowsPerPage={6}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
            <Grid container>
              <Grid
                display="flex"
                width={"100%"}
                flexDirection="row"
                justifyContent="space-between"
                m={2}
              >
                <Grid md={2} sm={4} xs={4} lg={2} xl={2}>
                  <Button
                    onClick={() => navigate("/reports", { replace: true })}
                    fullWidth
                    sx={{
                      border: "1px solid #91278F",
                      borderRadius: 2,
                      fontWeight: 600,
                      textTransform: "capitalize",
                      color: "#000000",
                    }}
                  >
                    Back To Reports
                  </Button>
                </Grid>
                <Grid md={2} sm={4} xs={4} lg={3} xl={2}>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate(manage_roles_and_access);
                      }}
                      fullWidth
                      color={"primary"}
                    >
                      Manage Roles and Access
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardActions>
      </Card>
    </Box >
  );
}
