import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import XLSIcon from "../../../assets/ph_file-xls.svg";
import AttachmentIcon from "../../../assets/attachment.svg";
import { ArrowBack, DownloadOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import UpdateJobsStatusToReject from "../components/update_jobs_status/update_jobs_status_popup";
import MainLayout from "../../../layouts/main/main_layout";
import Loading from "../../../components/loading/loading";
import useUser from "../../../providers/user/user_provider";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { queryClient } from "../../../providers/query/query";
import { JobApplicationDetailsModel } from "../model/job_application_details";
import {
  downloadJobApplicationDetails,
  downloadResume,
  getJobApplicationDetails,
  updateJobApplicationStatus,
} from "../../../services/jobs/jobs";
import { purpleLight } from "../../../theme/colors";
import { downloadFromBlob } from "../../../utils/utils";

export default function JobApplicationDetails() {
  const [closeRejectServiceStatusDialog, setCloseRejectServiceStatusDialog] =
    useState<boolean>(false);

  let { id } = useParams();
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const { showSnackbar } = useFeedback();

  const { data: jobDetails, isLoading } = useQuery<JobApplicationDetailsModel>(
    ["job-details", id],
    async () => {
      let data = await getJobApplicationDetails({ jobId: +id! });
      return data.data.data;
    }
  );

  function updateServiceStatus(comments: string, statusId: number) {
    updateJobApplicationStatus({
      comment: comments,
      statusId: statusId,
      jobId: +id!,
    })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Job status updated successfully");
          queryClient.invalidateQueries({ queryKey: ["job-details"] });
        }
      })
      .catch((e) => {
        if (
          e.response.status === 403
        ) {
          showSnackbar("You do not have permission to perform this operation.");
        } else {
          showSnackbar("Something went wrong");
        }
      });
  }

  function downloadExcel() {
    downloadJobApplicationDetails(jobDetails?.id!, "excel").then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".xlsx");
    });
  }

  function downloadResumeApiCall() {
    downloadResume(jobDetails?.id!).then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".pdf");
    });
  }
  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }
  function loading() {
    return (
      <Box
        height={500}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={7} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    Applicant Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {isLoading ? (
                  loading()
                ) : (
                  <Box overflow="auto" flex={1}>
                    <Box>
                      <Typography
                        variant="h6"
                        color="primary.main"
                        fontWeight="600"
                        mb={2}
                      >
                        Applicant info
                      </Typography>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        {listItem({
                          title: "Sl. No.",
                          value: jobDetails?.id,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Full Name",
                          value: jobDetails?.userInfo.userName,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Educational Qualification",
                          value: jobDetails?.userInfo.education,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        <Box>
                          {listItem({
                            title: "Date of birth",
                            value: jobDetails?.userInfo.dob,
                          })}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Occupation",
                          value: jobDetails?.userInfo.occupation,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "State",
                          value: jobDetails?.userInfo.state,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "District",
                          value: jobDetails?.userInfo.district,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "Total Experience",
                          value: jobDetails?.userInfo.workExperience,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Contact Number",
                          value: jobDetails?.userInfo.userContact,
                        })}
                      </Grid>
                    </Grid>
                    {jobDetails?.userInfo?.resume && (
                      <Grid item md={12} mt={2}>
                        <Typography color="primary.main">
                          Supporting Documents
                        </Typography>
                        <List>
                          <ListItem
                            sx={{
                              border: `1px solid ${purpleLight}`,
                              borderRadius: 4,
                              mb: 1,
                            }}
                          >
                            <ListItemIcon>
                              <img src={AttachmentIcon} alt="" />
                            </ListItemIcon>
                            <ListItemText primary={"Resume"}></ListItemText>
                            <ListItemIcon>
                              <IconButton
                                color="primary"
                                onClick={downloadResumeApiCall}
                              >
                                <DownloadOutlined />
                              </IconButton>
                            </ListItemIcon>
                          </ListItem>
                        </List>
                      </Grid>
                    )}
                    <Box mt={2} mb={2}>
                      <Divider />
                    </Box>

                    <Box>
                      <Typography
                        variant="h6"
                        color="primary.main"
                        fontWeight="600"
                        mb={2}
                      >
                        Job info
                      </Typography>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        {listItem({
                          title: "Sl. No.",
                          value: jobDetails?.id,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job Type",
                          value: jobDetails?.jobInfo.jobType,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job role / Position",
                          value: jobDetails?.jobInfo.jobPosition,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        <Box>
                          {listItem({
                            title: "Name of Company / Person",
                            value: jobDetails?.jobInfo.company,
                          })}
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "State",
                          value: jobDetails?.jobInfo.state,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "District",
                          value: jobDetails?.jobInfo.district,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "Experience Required",
                          value: jobDetails?.jobInfo.workExperience,
                        })}
                      </Grid>
                      <Grid item md={6}></Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job Contact number",
                          value: jobDetails?.jobInfo.jobContactNo,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Job Contact Email",
                          value: jobDetails?.jobInfo.jobEmailId,
                        })}
                      </Grid>
                    </Grid>
                    <Box mt={2} mb={2}>
                      <Divider />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography>Generate Report:</Typography>
                      <IconButton sx={{ ml: 2 }} onClick={downloadExcel}>
                        <img src={XLSIcon} alt="" />
                      </IconButton>
                    </Box>
                    <Box mt={2} mb={2}>
                      {listItem({
                        title: "Status",
                        value: jobDetails?.status,
                      })}
                    </Box>
                    {jobDetails?.remarks && (
                      <Grid item md={6} mt={2} mb={4}>
                        {listItem({
                          title: "Reason for Application Denial",
                          value: jobDetails?.remarks,
                        })}
                      </Grid>
                    )}
                    <Grid item md={12}>
                      <Box display="flex" justifyContent="space-between">
                        <Button
                          variant="outlined"
                          sx={{
                            pl: 2,
                            pr: 2,
                            textTransform: "capitalize",
                            fontWeight: 600,
                          }}
                          onClick={() => navigate(-1)}
                        >
                          Back To Applicants
                        </Button>
                        <Box>
                          {jobDetails?.status === "In Review" && hasPermission("jobs", "update") && (
                            <Button
                              // disabled={!hasPermission("jobs", "update")}
                              variant="outlined"
                              sx={{
                                pl: 4,
                                pr: 4,
                                textTransform: "capitalize",
                                fontWeight: 600,
                              }}
                              onClick={() => {
                                setCloseRejectServiceStatusDialog(true);
                              }}
                            >
                              Deny Listing
                            </Button>
                          )}

                          <Button
                            // disabled={jobDetails?.status !== "In Review"}
                            variant="contained"
                            sx={{
                              pl: 4,
                              pr: 4,
                              fontWeight: 600,
                              ml: 2,
                              opacity:
                                jobDetails?.status ===
                                  "Application Forwarded" ||
                                  jobDetails?.status === "Shortlisted" ||
                                  jobDetails?.status === "Application Denied"
                                  ? 0.5
                                  : 1,
                              backgroundColor:
                                jobDetails?.status ===
                                  "Application Forwarded" ||
                                  jobDetails?.status === "Shortlisted" ||
                                  jobDetails?.status === "In Review" ||
                                  jobDetails?.status === "Rejected"
                                  ? "#1E6446"
                                  : jobDetails?.status === "Application Denied"
                                    ? "#D14C2E"
                                    : "#91278F",
                            }}
                            onClick={() => {
                              if (jobDetails?.status === "In Review") {
                                updateServiceStatus("", 2);
                              }
                            }}
                          >
                            {jobDetails?.status === "Application Forwarded" ||
                              jobDetails?.status === "Shortlisted" ||
                              jobDetails?.status === "Rejected"
                              ? "Approved"
                              : jobDetails?.status === "In Review"
                                ? "Approve"
                                : "Rejected"}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {closeRejectServiceStatusDialog && (
        <UpdateJobsStatusToReject
          open={closeRejectServiceStatusDialog}
          onDialogClose={() => {
            setCloseRejectServiceStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            updateServiceStatus(comments, 3);
            setCloseRejectServiceStatusDialog(false);
          }}
          title={"Reject Job Applicant"}
          subtitle={"Reason for rejection:"}
        />
      )}
    </MainLayout>
  );
}
