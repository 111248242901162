import React, { useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import MainLayout from "../../layouts/main/main_layout";
import { Search } from "@mui/icons-material";
import FilterServices from "./components/filter_services/filter_services";
import DatGridServices from "./components/datagrid/datagrid_services";
import Loading from "../../components/loading/loading";
import styled from "@emotion/styled";
import { useFeedback } from "../../providers/feedback/feeedback";
import { PaginationState } from "../schemes/enquiries/enquiries_list";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getStateMasterData } from "../../services/master/master_service";
import { ServiceList } from "./model/service_model";
import {
  deleteService,
  downloadService,
  getFilterList,
  getServiceList,
  getStatusList,
  updateService,
} from "../../services/services/services";
import { queryClient } from "../../providers/query/query";
import { downloadFromBlob } from "../../utils/utils";
import UpdateServiceStatusToReject from "./components/update_service_status/update_service_status_popup";
import UpdateServiceStatusToDelete from "./components/update_service_status/update_service_status_popup";
import useUser from "../../providers/user/user_provider";
import ServiceLayout from "../../layouts/service/service_layout";
import AccessDenied from "../../components/access_denied/access_denied";
import { RECORDS_EXCEEDS_ALERT_MSG } from "../../constants";
import { getMasterData } from "../../services/choices/choices_services";

export interface ServicesLabel {
  label: string;
}

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function Services() {
  const { showSnackbar } = useFeedback();
  const [stateId, setStateId] = useState<number | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const [districtId, setDistrictId] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState<PaginationState>({
    page: 0,
    rowsPerPage: 8,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [closeRejectServiceStatusDialog, setCloseRejectServiceStatusDialog] =
    useState<boolean>(false);
  const [closeDeleteServiceStatusDialog, setCloseDeleteServiceStatusDialog] =
    useState<boolean>(false);
  const [ageFrom, setAgeFrom] = useState<number | null>(null);
  const [ageTo, setAgeTo] = useState<number | null>(null);
  const [gender, setGender] = useState("");
  const [sortInAcceding, setSortInAcceding] = useState(true);

  const [selectedUserId, setSelectedUserId] = useState<ServiceList | null>(
    null
  );
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const { data: services, isLoading } = useQuery(
    [
      "services",
      stateId,
      cityId,
      districtId,
      categoryId,
      selectedStatus,
      searchValue,
      page,
      startDate,
      endDate,
      ageFrom,
      gender,
      sortInAcceding
    ],
    async () => {
      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      let response = await getServiceList({
        size: 8,
        page: page + 1,
        category: categoryId,
        city: cityId,
        district: districtId,
        state: stateId,
        status: selectedStatus,
        keyword: searchValue,
        startDate: startDate,
        endDate: endDate,
        ageFrom: ageFrom,
        ageTo: ageTo,
        gender: gender,
        sortOrder: sortOrder
      });
      return response.data.data.data;
    }
  );

  const { data: masterDataList } = useQuery(["masterDataList"], async () => {
    let response = await getStateMasterData();
    return response?.data?.data?.list;
  });

  const { data: statusList } = useQuery(["statusList"], async () => {
    let response = await getStatusList();
    return response.data.data.list;
  });

  const { data: filterList } = useQuery(["filterList"], async () => {
    let response = await getFilterList();
    return response.data.data;
  });

  const { data: masterData } = useQuery(["masterData"], async () => {
    let response = await getMasterData();
    return response.data.data;
  });

  function updateServiceApiCall({
    statusId,
    serviceId,
  }: {
    statusId: number;
    serviceId: number;
  }) {
    updateService({
      comment: "",
      statusId: statusId,
      serviceId: serviceId,
    })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Service status updated successfully");
          queryClient.invalidateQueries({ queryKey: ["services"] });
        }
      })
      .catch((e) => {
        if (
          e.response.status === 403
        ) {
          showSnackbar("You do not have permission to perform this operation.");
        }
      })
  }
  function deleteServiceApiCall({ comments }: { comments: string }) {
    deleteService({ serviceId: +selectedUserId?.id!, comment: comments })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Service removed successfully");
          queryClient.invalidateQueries({ queryKey: ["services"] });
        }
      })
      .catch((e) => {
        if (
          e.response.status === 403
        ) {
          showSnackbar("You do not have permission to perform this operation.");
        }
      });
  }

  function updateServiceStatus(comments: string, statusId: number) {
    updateService({
      comment: comments,
      statusId: statusId,
      serviceId: +selectedUserId?.id!,
    })
      .then((res) => {
        console.log(res, 'res');
        if (res.status === 200) {
          showSnackbar("Service status updated successfully");
          queryClient.invalidateQueries({ queryKey: ["services"] });
        }
      })
      .catch((e) => {
        console.log(e.response, 'res');

        if (
          e.response.status === 403
        ) {
          showSnackbar("You do not have permission to perform this operation.");
        } else {
          showSnackbar("Something went wrong");
        }

      });
  }

  function downloadExcel() {
    let sortOrder = sortInAcceding ? "DESC" : "ASC";
    if (services?.total <= 1000) {
      downloadService({
        page: 1,
        size: 1000,
        category: categoryId,
        city: cityId,
        district: districtId,
        state: stateId,
        status: selectedStatus,
        startDate: startDate,
        endDate: endDate,
        ageFrom: ageFrom,
        ageTo: ageTo,
        gender: gender,
        format: "excel",
        sortOrder: sortOrder
      }).then((response) => {
        downloadFromBlob(response, new Date().valueOf() + ".xlsx");
      });
    } else {
      showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
    }
  }

  const servicesLabels: ServicesLabel[] = [
    {
      label: "Sl. No.",
    },
    {
      label: "Name of Service / Person",
    },
    {
      label: "Service Category",
    },
    {
      label: "State",
    },
    {
      label: "City",
    },
    {
      label: "District",
    },
    {
      label: "Service Contact Number",
    },
    {
      label: "Service Email ID",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  function searchComponent() {
    return (
      <Grid display={"flex"} flexDirection={"row"} container p={2}>
        <Grid md={12} sm={12} xs={12} lg={12} xl={12}>
          <TextField
            value={searchValue}
            onChange={(e) => {
              setPage(0);
              setSearchValue(e.target.value);
            }}
            size="small"
            fullWidth
            sx={{
              backgroundColor: "#F5E6F5",
              borderRadius: 3,
              borderColor: "transparent",
            }}
            placeholder="Search by service name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "text.primary" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <MainLayout>
      {hasPermission("services", "read") ? (
        <ServiceLayout
          downloadExcel={downloadExcel}
          setOpenModal={setOpenModal}
          setSortInAcceding={setSortInAcceding}
          sortInAcceding={sortInAcceding}
        >
          {searchComponent()}
          {isLoading ? (
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              height="100%"
            >
              <Loading />
            </Box>
          ) : (
            <DatGridServices
              labels={servicesLabels}
              data={services?.list || []}
              count={services?.total | 0}
              page={page}
              setPage={setPage}
              setSelectedUserId={setSelectedUserId}
              updateServiceApiCall={updateServiceApiCall}
              setCloseRejectServiceStatusDialog={
                setCloseRejectServiceStatusDialog
              }
              setCloseDeleteServiceStatusDialog={
                setCloseDeleteServiceStatusDialog
              }
            />
          )}
        </ServiceLayout>
      ) : (
        <Box display="flex" flex={1} width="100%" height="100%">
          <AccessDenied />
        </Box>
      )}
      <FilterServices
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        states={masterDataList || []}
        category={filterList?.categories || []}
        stateId={stateId}
        setStateId={setStateId}
        cityId={cityId}
        setCityId={setCityId}
        districtId={districtId}
        setDistrictId={setDistrictId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        resetFilter={() => {
          setCategoryId(null);
          setStateId(null);
          setSelectedStatus(null);
          setCityId(null);
          setDistrictId(null);
          setPage(0);
          setEndDate("");
          setStartDate("");
          setAgeFrom(null);
          setAgeTo(null);
          setGender("");
          setDistrictId(null)
        }}
        statusList={statusList}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        setPage={setPage}
        ageTo={ageTo}
        gender={gender}
        setGender={setGender}
        ageFrom={ageFrom}
        genderList={masterData?.gender}
        setAgeFrom={setAgeFrom}
        setAgeTo={setAgeTo}
      />
      {closeRejectServiceStatusDialog && (
        <UpdateServiceStatusToReject
          open={closeRejectServiceStatusDialog}
          onDialogClose={() => {
            setCloseRejectServiceStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            updateServiceStatus(comments, 4);
            setCloseRejectServiceStatusDialog(false);
          }}
          title={"Reject Service"}
          subtitle={"Reason for rejection:"}
        />
      )}
      {closeDeleteServiceStatusDialog && (
        <UpdateServiceStatusToDelete
          open={closeDeleteServiceStatusDialog}
          onDialogClose={() => {
            setCloseDeleteServiceStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            deleteServiceApiCall({ comments: comments });
            setCloseDeleteServiceStatusDialog(false);
          }}
          title={"Delete Service"}
          subtitle={"Reason for Deletion:"}
        />
      )}
    </MainLayout>
  );
}
