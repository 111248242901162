
import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReactWordcloud from 'react-wordcloud';
import { useFeedback } from '../../../../providers/feedback/feeedback';

interface Props {
    openPopup: boolean;
    setOpenPopup: (val: boolean) => void;
    needsSuggestedLabels: { text: string, value: number }[] | []
}

export default function WordCountPopup({ openPopup, setOpenPopup, needsSuggestedLabels }: Props) {

    const { showSnackbar } = useFeedback()

    const copyToClipboard = (word: string) => {
        navigator.clipboard.writeText(word);
        showSnackbar(`Copied ${word} to clipboard`)
    };


    return (
        <Dialog open={openPopup} sx={{ borderRadius: 24 }}>
            <DialogContent style={{ minWidth: "400px", }}>
                <Box style={{ minWidth: "400px", }}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="body1" fontWeight={600} textAlign={"center"} mt={2}>
                            Latest Suggestions
                        </Typography>

                        <IconButton onClick={() => setOpenPopup(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="caption" color={'#7C797C'}>
                            Click to copy label name
                        </Typography>
                    </Box>
                    <ReactWordcloud
                        words={needsSuggestedLabels}
                        callbacks={{
                            onWordClick: (word) => copyToClipboard(word.text),
                        }}
                    />
                </Box>
            </DialogContent>
        </Dialog >
    )
}
