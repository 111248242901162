import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Loading from "../../../components/loading/loading";
import MainLayout from "../../../layouts/main/main_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { deleteRoles } from "../../../services/admin/admin_services";
import DeleteRole from "../components/delete_role/delete_role";
import ViewRoleDetails from "../components/view_role_details/view_role_details";
import { RoleList } from "../model/admin_roles";
import RoleListingPage from "../components/role_listing_page/role_listing_page";
import useUser from "../../../providers/user/user_provider";
import { useQuery } from "react-query";
import { queryClient } from "../../../providers/query/query";
import { deleteClientRole, getRoles } from "../../../services/client_mapping/client_mapping";


export const roles: { id: number, moduleName: string }[] = [
  {
    id: 1,
    moduleName: 'Needs'
  },
  {
    id: 2,
    moduleName: 'Schemes'
  },
  {
    id: 3,
    moduleName: 'Choices'
  },
  {
    id: 4,
    moduleName: 'Services'
  },
  {
    id: 5,
    moduleName: 'Jobs'
  },
  // {
  //   id: 6,
  //   moduleName: 'Surveyor'
  // }
];

export const rolesAccess: { id: number, access: string }[] = [
  {
    id: 1,
    access: 'view'
  },
  {
    id: 2,
    access: 'create'
  },
  {
    id: 3,
    access: 'update'
  },
  {
    id: 4,
    access: 'delete'
  }
];

export function moduleValue(currentTab: number) {
  switch (currentTab) {
    case 1:
      return 'all';
    case 2:
      return 'needs';
    case 3:
      return 'schemes';
    case 4:
      return 'choices';
    case 5:
      return 'services';
    case 6:
      return 'jobs';
    default:
      return 'all'
  }
}

export default function RoleManagement() {
  const [deleteRole, setDeleteRole] = useState(false);

  const [selectedRoleDetails, setSelectedRoleDetails] = useState<RoleList>(
    {} as RoleList
  );
  const [viewSelectedRoleDetails, setViewSelectedRoleDetails] =
    useState<boolean>(false);

  const [searchValue, setSearchValue] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const { showSnackbar } = useFeedback();
  const [page, setPage] = useState(0);
  const { hasPermission } = useUser();




  const { data: roleList } = useQuery(
    ["get-client-Roles", currentTab, page, searchValue],
    async () => {
      let response = await getRoles({
        page: page + 1, size: 8,
        module: moduleValue(currentTab + 1),
        keyword: searchValue
      });
      return response.data.data;
    }
  );

  const deleteAdminRolesApiCall = () => {
    setDeleteRole(false);
    deleteClientRole({ roleId: selectedRoleDetails.id })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar(res?.data?.data?.message);
          queryClient.invalidateQueries({ queryKey: ["get-client-Roles"] });
        } else {
          showSnackbar("Something went wrong");
        }
      })
      .catch((e) => {
        setDeleteRole(false);
        showSnackbar("Something went wrong");
      });
  };

  useEffect(() => {
    setSearchValue('')
  }, [currentTab])

  return (
    <MainLayout>
      <Box>
        {/* {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              width: "100%",
            }}
          >
            <Loading />
          </Box>
        ) : hasPermission("Users", "Read") ? ( */}
        <RoleListingPage
          searchList={roleList?.list}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setViewSelectedRoleDetails={setViewSelectedRoleDetails}
          setSelectedRoleDetails={setSelectedRoleDetails}
          roleList={roleList?.list! || []}
          setDeleteRole={setDeleteRole}
          currentTab={currentTab}
          handleChange={(val) => { setCurrentTab(val) }}
          page={page}
          setPage={setPage}
          count={roleList?.total}

        />
        {/* ) : (
          <Box display="flex" flex={1} width="100%" height="100%">
            <AccessDenied />
          </Box>
        )} */}
        <ViewRoleDetails
          viewSelectedRoleDetails={viewSelectedRoleDetails}
          setViewSelectedRoleDetails={setViewSelectedRoleDetails}
          selectedRoleDetails={selectedRoleDetails}
        />
        <DeleteRole
          deleteRole={deleteRole}
          setDeleteRole={setDeleteRole}
          selectedRoleDetails={selectedRoleDetails}
          deleteAdminRolesApiCall={deleteAdminRolesApiCall}
        />
      </Box>
    </MainLayout>
  );
}
