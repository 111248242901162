import { Close, } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

interface Props {
    openPopup: boolean;
    setOpenPopup: (val: boolean) => void;
    createLabel: (val: string) => void;
}

export default function CreateLabelPopup({ openPopup, setOpenPopup, createLabel }: Props) {
    const [labelName, setSelectedLabelName] = useState<string>('');
    const [error, setError] = useState(false)
    return (
        <Dialog open={openPopup} sx={{ borderRadius: 16 }}>
            <DialogContent style={{ minWidth: "500px", borderRadius: 20 }}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h5" fontWeight={600} textAlign={"center"} mt={3}>
                        Create Label
                    </Typography>
                    <IconButton onClick={() => setOpenPopup(false)}>
                        <Close />
                    </IconButton>
                </Box>
                <Box mt={3}>
                    <Box>
                        <Typography variant='caption' color='#7C797C'>Minimum of 1 words and maximum if 3 words and Maximum of 40 characters are allowed.</Typography>
                    </Box>
                </Box>
                <Box mt={3}>
                    <TextField fullWidth error={error} onChange={(e) => {
                        setSelectedLabelName(e.target.value);
                        setError(false)
                    }} value={labelName} label='Label Name' />
                    {error && <Typography variant='caption' color='red'>Please enter valid need label</Typography>}
                </Box>
                <Box mt={3}>
                    <Typography variant='caption' color='#7C797C'>
                        Please note: Any relevant grievances to the newly created incident label will be mapped after a few hours.
                    </Typography>
                </Box>
                <Box display={'flex'} mt={4} justifyContent={'space-between'}>
                    <Button onClick={() => {
                        setOpenPopup(false)
                    }}
                        variant='text'

                    >
                        Close
                    </Button>
                    <Button onClick={() => {
                        if (labelName?.split(' ').length >= 1 && labelName?.split(' ').length <= 3 && labelName?.length <= 40) {
                            createLabel(labelName)
                        } else {
                            setError(true)
                        }
                    }}
                        sx={{ minWidth: 200 }}
                        variant='contained'
                        size='large'
                    >
                        Create
                    </Button>
                </Box>
            </DialogContent>
        </Dialog >
    )
}
