import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tab,
  TablePagination,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/loading/loading";
import { PointsStatement } from "../model/points_statement";
import RedeemPopup from "../component/redeem_popup/redeem_popup";
import { useQuery } from "react-query";
import {
  getRedemptionHistory,
  getRewardsListById,
  getRewardsStatement,
  redemption,
  updateRedemptionComments,
} from "../../../services/rewards/rewards";
import { RewardsDetailsModel } from "../model/reward_details_model";
import { RedemptionHistory } from "../model/redemption_history";
import RedemptionHistoryComp from "../component/redemption_history/redemption_history";
import { useFeedback } from "../../../providers/feedback/feeedback";
import RewardStatement from "../../reports/reward_statement/reward_statement";
import { queryClient } from "../../../providers/query/query";
import useUser from "../../../providers/user/user_provider";

export default function RewardsDetails() {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [openRewardPopup, setOpenRewardPopup] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useFeedback();
  const [page, setPage] = useState(0);
  const [stamentPage, setStatementPage] = useState(0);
  const { id } = useParams();
  const { hasPermission } = useUser();

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  const { data: rewardsList, isLoading } = useQuery<RewardsDetailsModel>(
    ["reward-details"],
    async () => {
      let response = await getRewardsListById({
        userId: +id!,
      });
      return response?.data?.data;
    }
  );

  const { data: rewardStatement } = useQuery(
    ["reward-statement", page, id],
    async () => {
      let response = await getRewardsStatement({
        userId: +id!,
        page: page + 1,
        size: 8,
      });
      return response?.data?.data || [];
    }
  );

  function redemptionApi(comments: string, points: number) {
    redemption({ userId: +id!, comments: comments, points: points })
      .then((res) => {
        showSnackbar("Points redeemed successfully");
        queryClient.invalidateQueries({
          queryKey: ["reward-details"],
        });
        queryClient.invalidateQueries({
          queryKey: ["reward-statement"],
        });
        queryClient.invalidateQueries({
          queryKey: ["redemption-history"],
        });
      })
      .catch((e) => {
        if (e?.response?.data?.error?.message) {
          showSnackbar(e.response.data.error.message);
        } else {
          showSnackbar("Something went wrong");
        }
      });
    setOpenRewardPopup(false);
  }

  const { data: redemptionHistory } = useQuery(
    ["redemption-history", stamentPage, id],
    async () => {
      let response = await getRedemptionHistory({
        userId: +id!,
        page: stamentPage + 1,
        size: 8,
      });
      return response?.data?.data || [];
    }
  );

  function updateRedemptionApiCall(comments: string, redemptionId: number) {
    updateRedemptionComments({ comments: comments, userId: +redemptionId! })
      .then((res) => {
        showSnackbar("Note Added successfully");
        queryClient.invalidateQueries({
          queryKey: ["redemption-history"],
        });
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
      });
  }

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }

  function loading() {
    return (
      <Box
        height={500}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleStatementChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setStatementPage(newPage);
  };

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={7} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                    Reward Points Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {isLoading ? (
                  loading()
                ) : (
                  <Box overflow="auto" flex={1}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        {listItem({
                          title: "UID",
                          value: rewardsList?.userId,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Full Name",
                          value: rewardsList?.name,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Contact Number",
                          value: rewardsList?.mobileNumber,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Age",
                          value: rewardsList?.age,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "Gender",
                          value: rewardsList?.gender,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "Occupation",
                          value: rewardsList?.occupation,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "State",
                          value: rewardsList?.state,
                        })}
                      </Grid>
                      <Grid item md={6}>
                        {listItem({
                          title: "District",
                          value: rewardsList?.district,
                        })}
                      </Grid>
                    </Grid>

                    <Box mt={2} mb={2}>
                      <Divider />
                    </Box>

                    <Box>
                      <Typography variant="h6" fontWeight="600">
                        Reward Points Details
                      </Typography>
                    </Box>
                    <Grid container spacing={3} mt={2}>
                      <Grid item md={6}>
                        {listItem({
                          title: "Current reward points",
                          value: rewardsList?.currentPoints,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "Used reward points",
                          value: rewardsList?.redeemedPoints,
                        })}
                      </Grid>

                      <Grid item md={6}>
                        {listItem({
                          title: "Total reward points gained",
                          value: rewardsList?.totalPoints,
                        })}
                      </Grid>
                    </Grid>

                    <Tabs
                      value={currentTab}
                      aria-label="basic tabs example"
                      onChange={handleChange}
                      sx={{
                        "MuiTabs-root css-112qdbf-MuiTabs-root": {
                          borderColor: "#F5E6F5",
                        },
                      }}
                    >
                      <Tab
                        label="Points statement"
                        sx={{
                          textTransform: "capitalize",
                          color: "#1B001B",
                        }}
                      />
                      <Tab
                        label="Redemption history"
                        sx={{ textTransform: "capitalize", color: "#1B001B" }}
                      />
                    </Tabs>

                    {currentTab === 0 && (
                      <Box mt={3}>
                        {rewardStatement?.list?.map(
                          (pointHistory: PointsStatement) => {
                            return (
                              <RewardStatement pointHistory={pointHistory} />
                            );
                          }
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            width: "100%",
                          }}
                          mt={1}
                        >
                          <TablePagination
                            component="div"
                            rowsPerPageOptions={[]}
                            count={rewardStatement?.total || 0}
                            rowsPerPage={8}
                            page={page}
                            onPageChange={handleChangePage}
                          />
                        </Box>
                      </Box>
                    )}
                    {currentTab === 1 && (
                      <Box>
                        {redemptionHistory?.list ? (
                          redemptionHistory?.list?.map(
                            (history: RedemptionHistory) => {
                              return (
                                <RedemptionHistoryComp
                                  history={history}
                                  onSubmit={(id, comments) => {
                                    updateRedemptionApiCall(
                                      comments,
                                      history.id
                                    );
                                  }}
                                />
                              );
                            }
                          )
                        ) : (
                          <Box
                            mt={3}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Typography>No history found</Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            width: "100%",
                          }}
                          mt={1}
                        >
                          <TablePagination
                            component="div"
                            rowsPerPageOptions={[]}
                            count={redemptionHistory?.total || 0}
                            rowsPerPage={8}
                            page={stamentPage}
                            onPageChange={handleStatementChangePage}
                          />
                        </Box>
                      </Box>
                    )}

                    <Box>
                      <Button
                        disabled={
                          !hasPermission("rewards", "update") ||
                            +rewardsList?.currentPoints! === 0
                            ? true
                            : false
                          // : false
                        }
                        variant="contained"
                        sx={{ mt: 2, minWidth: 170 }}
                        onClick={() => setOpenRewardPopup(true)}
                      >
                        Redeem
                      </Button>
                    </Box>
                    <Divider sx={{ backgroundColor: "#F5E6F5", my: 3 }} />
                    <Grid item md={12} mt={4}>
                      <Box display="flex" justifyContent="space-between">
                        <Button
                          variant="outlined"
                          sx={{
                            pl: 2,
                            pr: 2,
                            textTransform: "capitalize",
                            fontWeight: 600,
                            minWidth: 170,
                          }}
                          onClick={() => navigate(-1)}
                        >
                          Back To Rewards
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {openRewardPopup && (
        <RedeemPopup
          open={openRewardPopup}
          onDialogClose={() => {
            setOpenRewardPopup(false);
          }}
          updateRewards={(remarks, points) => {
            redemptionApi(remarks, points);
          }}
          rewardsList={rewardsList || null}
        />
      )}
    </MainLayout>
  );
}
