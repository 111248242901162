import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { getUserPermissions } from "../../services/user/permissions";
import Session from "../../utils/session";
import { ClientPermissions, Permission } from "../../models/permissions";




interface UserContextInterface {
  userPermissions: ClientPermissions | null;
  getFeaturePermissions: ((feature: string) => Permission | null) | null;
  hasPermission: (feature: string, permission: string) => boolean;
  accessToken: string | null;
  setAccessToken: (feature: string, permission: string) => void;
  refreshToken: string | null;
  setRefreshToken: (feature: string, permission: string) => void;
  login: (accessToken: string, refreshToken: string) => void;
  logout: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

const UserContext = createContext<UserContextInterface>({
  userPermissions: null,
  getFeaturePermissions: () => null,
  hasPermission: () => false,
  accessToken: null,
  setAccessToken: () => { },
  refreshToken: null,
  setRefreshToken: () => { },
  login: () => { },
  logout: () => { },
  loading: false,
  setLoading: () => null
});

export function UserProvider({ children }: PropsWithChildren) {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (Session.isLoggedIn()) {
      setAccessToken(Session.getToken());
      setRefreshToken(Session.getRefreshToken());
    } else {
      setAccessToken(null);
      setRefreshToken(null);
    }
  }, []);

  const { data: clientPermission, isLoading } = useQuery<ClientPermissions>(
    "permissions-api",
    async () => {
      let { data } = await getUserPermissions();
      return data.data || null;
    },
    {
      enabled: accessToken != null,
    }
  );


  function getFeaturePermissions(feature: string): Permission | null {
    if (clientPermission != null) {
      return clientPermission?.permissions.find(
        (_feature) => _feature.module === feature
      )!
    } else {
      return null;
    }
  }

  function hasPermission(feature: string, permission: string) {
    let _feature = getFeaturePermissions(feature);

    return _feature?.actions.find(
      (_permission) => _permission === permission
    )
      ? true
      : false;

  }

  function login(accessToken: string, refreshToken: string) {
    Session.login(accessToken, refreshToken);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  }

  function logout() {
    Session.logout();
    setAccessToken(null);
    setRefreshToken(null);
  }



  return (
    <UserContext.Provider
      value={{
        userPermissions: clientPermission || null,
        getFeaturePermissions: getFeaturePermissions,
        hasPermission: hasPermission,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        login,
        logout,
        loading: isLoading,
        setLoading
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default function useUser() {
  return useContext(UserContext);
}
