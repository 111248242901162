import { Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../constants";
import useUser from "../providers/user/user_provider";
import NotFound from "../views/not_found/not_found";
import Loading from "../components/loading/loading";
import MainLayout from "../layouts/main/main_layout";

interface PrivateRouteProps {
  element: JSX.Element;
  module?: string;
  path?: string

}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, module, path, ...rest }) => {
  let { userPermissions } = useUser();
  let token = sessionStorage.getItem(ACCESS_TOKEN);
  return token
    ?
    userPermissions?.superAdmin ?
      element : module === userPermissions?.permissions[0]?.module
        ? element : <NotFound />
    :
    <Navigate to="/" />;
}


export default PrivateRoute;
