import dayjs from "dayjs";
import HttpClient from "../../utils/http";
import {
  getNeedLabels,
  getSuggestedLabel,
  needDetails,
  needStatus,
  needsArea,
  needsBrand,
  needsDownload,
  needsFilters,
  needsList,
  updateNeeds,
} from "../constants/api_endpoints";

export function getNeeds({
  paginationModel,
  concerningToId,
  subcategoryId,
  areasId,
  status,
  cityId,
  startDate,
  endDate,
  sortOrder,
  state,
  keyword,
  district,
  label
}: {
  paginationModel: { pageSize: number; page: number };
  concerningToId: number | null;
  subcategoryId: number | null;
  areasId: number | null;
  status: number | null;
  cityId: number | null;
  startDate: string | null;
  endDate: string | null;
  sortOrder: string | null;
  state: number | null;
  keyword:string;
  district: number | null;
  label:string 
}) {
  let url =
    needsList +
    `?size=${paginationModel.pageSize}&page=${paginationModel.page + 1}`;

  if (concerningToId) {
    url += `&categoryId=${concerningToId}`;
  }
  if (subcategoryId) {
    url += `&subCategoryId=${subcategoryId}`;
  }
  if (areasId) {
    url += `&areaId=${areasId}`;
  }
  if (status) {
    url += `&statusId=${status}`;
  }
  if (sortOrder) {
    url += `&sortOrder=${sortOrder}`;
  }
  if (state) url += `&stateId=${state}`;

  if (label) url += `&label=${label}`;

  if (startDate && endDate) {
    let formattedStartDate = dayjs(startDate, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    let formattedEndDate = dayjs(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    url += `&dateFrom=${formattedStartDate}`;
    url += `&dateTo=${formattedEndDate}`;
  }

  // if(status){
  //   url+=`&areaId=${status}`;
  // }
  if (cityId) {
    url += `&cityId=${cityId}`;
  }
  if (district) {
    url += `&districtId=${district}`;
  }
  if(keyword!==""){
    url += `&keyword=${keyword}`
  }
  return HttpClient.get(url);
}

export function downloadNeeds({
  page,
  size,
  concerningToId,
  subcategoryId,
  areasId,
  status,
  cityId,
  startDate,
  endDate,
  format,
  sortOrder,
  state
}: {
  page: number | null;
  size: number | null;
  concerningToId: number | null;
  subcategoryId: number | null;
  areasId: number | null;
  status: number | null;
  cityId: number | null;
  startDate: string | null;
  endDate: string | null;
  format: string;
  sortOrder: string | null;
  state: number | null;
}) {
  let url = needsDownload + `?download=true&format=${format}`;

  if (concerningToId) {
    url += `&categoryId=${concerningToId}`;
  }
  if (subcategoryId) {
    url += `&subCategoryId=${subcategoryId}`;
  }
  if (areasId) {
    url += `&areaId=${areasId}`;
  }

  if (startDate && endDate) {
    let formattedStartDate = dayjs(startDate, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    let formattedEndDate = dayjs(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    url += `&dateFrom=${formattedStartDate}`;
    url += `&dateTo=${formattedEndDate}`;
  }

  // if(status){
  //   url+=`&areaId=${status}`;
  // }
  if (cityId) {
    url += `&cityId=${cityId}`;
  }
  if (sortOrder) {
    url += `&sortOrder=${sortOrder}`;
  }
  if (state) url += `&state=${state}`;
  return HttpClient.get(url, { responseType: "blob" });
}

export function getNeed(id: string) {
  return HttpClient.get(`${needDetails}?needId=${id}`);
}

export function downloadNeed(id: string, format: string) {
  return HttpClient.get(
    `${needDetails}?needId=${id}&download=true&format=${format}`,
    { responseType: "blob" }
  );
}

export function patchNeedStatus(
  id: string,
  formData:FormData
  // status: number | null,
  // comment: string | null,
  // notes: string | null,
  // sysgenCategory?: number | null,
  // sysgenSubCategory?: number | null | string,
  // sysgenArea?: number | null | string,
  // sysgenBrand?: number | null | string
) {
  return HttpClient.patch(`${needStatus}${id}`,formData, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
});
}

export function getNeedsFilters() {
  return HttpClient.get(needsFilters);
}

export function getArea({
  categoryId,
  subcategoryId,
}: {
  categoryId: number | null;
  subcategoryId: number | null;
}) {
  let url = `${needsArea}?categoryId=${categoryId}&subcategoryId=${subcategoryId}`;

  return HttpClient.get(url, { headers: { locale: "en" } });
}

export function getBrand({
  categoryId,
  subcategoryId,
}: {
  categoryId: number | null;
  subcategoryId: number | null;
}) {
  let url = `${needsBrand}?categoryId=${categoryId}&subcategoryId=${subcategoryId}`;

  return HttpClient.get(url, { headers: { locale: "en" } });
}


export function getNeedlabelsApi({
  page,
  size,
  days
}: {
  page: number | null;
  size: number | null;
  days:number | null;
}) {
  let url = `${getNeedLabels}?page=${page}&size=${size}&days=${days}`;
  return HttpClient.get(url);
}

export function getNeedSuggestedlabelsApi({
  fromDate,
  toDate,
}: {
  fromDate: string | null;
  toDate: string | null;
}) {
  let url = `${getSuggestedLabel}?fromDate=${fromDate}&toDate=${toDate}`;
  return HttpClient.get(url);
}

export function postNeedlabels({
  label,
}: {
  label: string;
}) {
  let url = `${getNeedLabels}`;
  return HttpClient.post(url,{
    "label": label
  });
}

export function patchBulkNeeds({
  needId,
  status,
  comments,
  selectedFile
}: {
  needId: number[];
  status:number;
  comments:string;
  selectedFile:File |null
}) {
  var formData = new FormData();
  formData.append("needId", JSON.stringify(needId));
  formData.append('status', status.toString());
  if(selectedFile)
    formData.append('file',selectedFile)
  if(comments)
    formData.append('comments', comments);
  let url = `${updateNeeds}`;
  return HttpClient.patch(url,formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

