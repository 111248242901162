import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import DatGrid from '../components/data_grid/data_grid';
import { useQuery } from 'react-query';
import { useFeedback } from '../../../providers/feedback/feeedback';
import { downloadNeeds, getNeeds, patchBulkNeeds } from '../../../services/needs/needs_service';
import BulkChangeStatusPopup from '../components/bulk_change_status_popup/bulk_change_status_popup';
import XLSIcon from "../../../assets/ph_file-xls.svg";
import PDFIcon from "../../../assets/ph_file-pdf.svg";
import { RECORDS_EXCEEDS_ALERT_MSG } from '../../../constants';
import { downloadFromBlob } from '../../../utils/utils';
import Sort from "../../../assets/sort.svg";
import FilterIcon from "../../../assets/filter_list.svg";
import NeedFiltersModel from '../components/needs_filter/needs_filter';
import { getStateMasterData } from '../../../services/master/master_service';
import { queryClient } from '../../../providers/query/query';

export default function IncidentGroupingNeedsList({ selectedLabel }: { selectedLabel: string }) {
    const [selectedStatus, setSelectedStatus] = useState<number | null>(1)
    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
    const [concerningToId, setConcerningToId] = useState<number | null>(null);
    const [subcategoryId, setSubcategoryId] = useState<number | null>(null);
    const [areasId, setAreasId] = useState<number | null>(null);
    const [brandId, setBrandId] = useState<number | null>(null);
    const [cityId, setCityId] = useState<number | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [sortInAcceding, setSortInAcceding] = useState(true);
    const [stateId, setStateId] = useState<number | null>(null);
    const [districtId, setDistrictId] = useState<number | null>(null);
    const [searchValue, setSearchValue] = useState("");
    const { showSnackbar } = useFeedback();
    const [selectedNeedsIds, setSelectedNeedsIds] = useState<number[]>([])
    const [openPopup, setOpenPopup] = useState(false)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 8,
        page: 0,
    });

    const { data: masterDataList } = useQuery(["masterDataList-incident-labelling",], async () => {
        let response = await getStateMasterData();
        return response?.data?.data?.list;
    });

    let status: { id: number, name: string }[] = [

        {
            id: 1,
            name: 'Submitted'
        },
        {
            id: 2,
            name: 'In Progress'
        },
        {
            id: 3,
            name: 'Closed'
        },
    ]

    const needsLabels: { label: string }[] = [
        {
            label: "Ref. No.",
        },
        {
            label: "Full Name",
        },
        {
            label: "Contact Number",
        },
        {
            label: "Category",
        },
        {
            label: "Sub Category",
        },
        {
            label: "Brand",
        },
        {
            label: "Area",
        },
        {
            label: "State",
        },
        {
            label: "City",
        },
        {
            label: "Status",
        },
    ];

    const { data, isLoading } = useQuery(
        [
            "needs-labelling",
            concerningToId,
            subcategoryId,
            areasId,
            selectedStatus,
            cityId,
            startDate,
            endDate,
            sortInAcceding,
            stateId,
            searchValue,
            districtId,
            selectedLabel,
            paginationModel,
        ],
        async () => {
            try {
                let sortOrder = sortInAcceding ? "DESC" : "ASC";
                let response = await getNeeds({
                    paginationModel,
                    concerningToId,
                    subcategoryId,
                    areasId,
                    status: selectedStatus,
                    cityId,
                    startDate,
                    endDate,
                    sortOrder,
                    state: stateId,
                    keyword: searchValue,
                    district: districtId,
                    label: selectedLabel
                });
                return response?.data?.data?.data;

            } catch (error: any) {
                if (error.response?.status === 404) {
                    queryClient.setQueryData(["needs-labelling"], []); // Clear cached data
                    return []; // Return an empty array to update UI
                }
                throw error; // Rethrow for other errors
            }
        }
    );

    function patchBulkNeedsApiCall(comments: string, status: number, selectedFile: File | null) {
        patchBulkNeeds({
            comments: comments,
            needId: selectedNeedsIds,
            status: status,
            selectedFile: selectedFile
        }).then((res) => {
            showSnackbar('Needs Status Updated');
            queryClient.refetchQueries({ queryKey: ["needs-labelling"] });
            setSelectedNeedsIds([])
        }).catch((e) => {
            showSnackbar('Something went wrong')
        })
        setOpenPopup(false)
    }

    function downloadPdf() {
        let sortOrder = sortInAcceding ? "DESC" : "ASC";
        if (data?.total <= 1000) {
            downloadNeeds({
                page: 1,
                size: 1000,
                concerningToId,
                subcategoryId,
                areasId,
                status: selectedStatus,
                cityId,
                startDate,
                endDate,
                format: "pdf",
                sortOrder: sortOrder,
                state: stateId,
                // keyword: searchValue
            }).then((response) => {
                downloadFromBlob(response, new Date().valueOf() + ".pdf");
            });
        } else {
            showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
        }
    }

    function downloadExcel() {
        let sortOrder = sortInAcceding ? "DESC" : "ASC";
        if (data?.total <= 1000) {
            downloadNeeds({
                page: 1,
                size: 1000,
                concerningToId,
                subcategoryId,
                areasId,
                status: selectedStatus,
                cityId,
                startDate,
                endDate,
                format: "excel",
                sortOrder: sortOrder,
                state: stateId,
            }).then((response) => {
                downloadFromBlob(response, new Date().valueOf() + ".xlsx");
            });
        } else {
            showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
        }
    }

    function resetFilters() {
        setFilterDialogOpen(false);
        setConcerningToId(null);
        setSubcategoryId(null);
        setAreasId(null);
        setSelectedStatus(null);
        setCityId(null);
        setStartDate(null);
        setEndDate(null);
        setStateId(null);
        setDistrictId(null);
    }

    return (
        <Box mt={4}>
            <Box display={'flex'} flexDirection={'row'} >
                <Typography>Incident Label:</Typography>
                <Typography fontWeight={600} ml={1}>
                    {selectedLabel}
                </Typography>
            </Box>
            <Box
                mt={2}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Box display={'flex'} flexDirection={'row'} >
                    {status?.map((item) => {
                        return <Button
                            onClick={() => setSelectedStatus(item?.id)} sx={{ mr: 1, height: 'fit-content' }}
                            variant={selectedStatus === item?.id ? 'contained' : 'outlined'}>
                            {item?.name}
                        </Button>
                    })}
                </Box>
                <Box>
                    <Box
                        flex={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="end"
                        >
                            <Typography>Download as:</Typography>
                            <IconButton onClick={downloadExcel}>
                                <img src={XLSIcon} alt="" />
                            </IconButton>
                            <IconButton onClick={downloadPdf}>
                                <img src={PDFIcon} alt="" />
                            </IconButton>
                        </Box>
                        <Box
                            ml={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="end"
                        >
                            <Typography>Sort By: </Typography>
                            <Box
                                ml={1}
                                display="flex"
                                flexDirection={"row"}
                                alignItems="center"
                            >
                                <Typography color={"#91278F"}>
                                    {sortInAcceding ? "New to old" : "Old to New"}
                                </Typography>
                                <IconButton
                                    onClick={() => {
                                        setSortInAcceding(!sortInAcceding);
                                    }}
                                >
                                    <img src={Sort} alt="" />
                                </IconButton>
                            </Box>
                        </Box>

                        <Box
                            ml={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="end"
                        >
                            <Typography
                                display="flex"
                                alignItems={"center"}
                                color="#91278F"
                                fontWeight="600"
                            >
                                Filters:
                            </Typography>
                            <IconButton onClick={() => setFilterDialogOpen(true)}>
                                <img src={FilterIcon} alt="" />
                            </IconButton>
                        </Box>
                    </Box>
                    {/* {searchComponent()} */}
                </Box>

            </Box>
            <DatGrid
                count={data?.total || 0}
                data={data?.list || []}
                labels={needsLabels}
                page={paginationModel?.page || 0}
                setPage={setPaginationModel}
                selectedNeedsIds={selectedNeedsIds}
                setSelectedNeedIds={setSelectedNeedsIds}
                setOpenPopup={setOpenPopup}
                selectedStatus={selectedStatus}
            />
            {openPopup && <BulkChangeStatusPopup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                updateNeed={(statusId, feedback, selectedFile) => {
                    patchBulkNeedsApiCall(feedback, statusId, selectedFile)
                }} />}
            {filterDialogOpen ? (
                <NeedFiltersModel
                    districtId={districtId}
                    states={masterDataList || []}
                    setStateId={setStateId}
                    stateId={stateId}
                    open={filterDialogOpen}
                    onDialogClose={() => {
                        setFilterDialogOpen(false);
                        setPaginationModel({ ...paginationModel, page: 0 });
                    }}
                    setAreasId={setAreasId}
                    setConcerningToId={setConcerningToId}
                    areasId={areasId}
                    setStatus={setSelectedStatus}
                    cityId={cityId}
                    setCityId={setCityId}
                    setSubcategoryId={setSubcategoryId}
                    status={selectedStatus}
                    subcategoryId={subcategoryId}
                    concerningToId={concerningToId}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    resetFilters={resetFilters}
                    brandId={brandId}
                    setBrandId={setBrandId}
                    setDistrictId={setDistrictId}
                />
            ) : null}
        </Box>
    )
}
