import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import useUser from "../../providers/user/user_provider";

export default function Breadcrumb() {
  const { userPermissions } = useUser()
  const { pathname } = useLocation();
  const paths = pathname.split("/").filter((path) => path);
  function getByTitle(path: string): string | null {
    return routes.find((route) => route.path == path)?.title || null;
  }

  return (
    <Box>
      <Breadcrumbs sx={{ whiteSpace: "nowrap" }}>
        <Link to="/reports">
          {userPermissions?.superAdmin && <Typography
            color={pathname !== "/reports" ? "primary.main" : "#1B001B"}
            fontWeight={700}
          >
            Reports
          </Typography>}
        </Link>
        {pathname !== "/reports" &&
          paths?.map((path, index) => {
            let constructPath = "/" + paths.slice(0, index + 1).join("/");
            let title = getByTitle(constructPath);
            let isEndPath: boolean = paths.length - 1 == index;
            return (
              <Link to={title ? constructPath : ""}>
                <Typography
                  textTransform={"capitalize"}
                  color={isEndPath ? "text.primary" : "primary.main"}
                  fontWeight={700}
                >
                  {title || path}
                </Typography>
              </Link>
            );
          })}
      </Breadcrumbs>
    </Box>
  );
}
