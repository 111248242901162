import { Box, Button, CardActions, TablePagination, Typography } from '@mui/material'
import React, { useState } from 'react'
import DurationPopup from '../components/duration_popup/duration_popup'
import CreateLabelPopup from '../components/create_label_popup/create_label_popup'
import WordCountPopup from '../components/word_cloud_popup/word_cloud_popup'
import { useQuery } from 'react-query'
import { getNeedlabelsApi, getNeedSuggestedlabelsApi, postNeedlabels } from '../../../services/needs/needs_service'
import { useFeedback } from '../../../providers/feedback/feeedback'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import useUser from '../../../providers/user/user_provider'


const durationList: { id: number, duration: string, days: number }[] = [
    { id: 1, duration: 'Last 15 Days', days: 15 },
    { id: 2, duration: 'Last 30 Days', days: 30 },
    { id: 3, duration: 'Last 90 Days', days: 90 },
    { id: 4, duration: 'Last 180 Days', days: 180 },
    { id: 4, duration: 'Last 365 Days', days: 365 }
]

export default function IncidentGroupingLabelList({
    setStepper,
    setSelectedLabel
}:
    {
        setStepper: (val: number) => void;
        setSelectedLabel: (val: string) => void
    }) {
    const [selectedDuration, setSelectedDuration] = useState(durationList[0])
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupForWordCount, setOpenPopupForWordCount] = useState(false);
    const { showSnackbar } = useFeedback()
    const [page, setPage] = useState(0)
    const navigate = useNavigate()

    const { hasPermission } = useUser();

    const { data: needsLabels } = useQuery(["needs-label", selectedDuration, page], async () => {
        let response = await getNeedlabelsApi({ page: page + 1, size: 8, days: selectedDuration?.days });
        return response?.data?.data;
    });

    const { data: needsSuggestedLabels } = useQuery<{ incidentLabel: string, count: number }[]>(
        ["needs-suggested-label", selectedDuration],
        async () => {
            let response = await getNeedSuggestedlabelsApi({
                fromDate: dayjs().subtract(selectedDuration?.days, "day").format("YYYY-MM-DD"),
                toDate: dayjs().format("YYYY-MM-DD"),
            });
            return response?.data?.data?.list;
        }
    );


    function createLabelApiCall(label: string) {
        postNeedlabels({ label: label }).then((res) => {
            showSnackbar('Label Created successfully')
        }).catch((e) => {
            showSnackbar('Something went wrong')
        })
        setOpenPopup(false)
    }
    const startRange = page * 8 + 1;
    const endRange = Math.min((page + 1) * 8, needsLabels?.total);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => { };

    const customText = `Showing ${startRange} to ${endRange} of ${needsLabels?.total} entries`;


    return (
        <Box mt={4}>
            <Box display={'flex'} justifyContent={'space-between'} >
                <Box display={'flex'} flexDirection={'row'}>
                    <Typography fontWeight={600}>Labels created in:</Typography>
                    <DurationPopup
                        onStatusChange={(val) => setSelectedDuration(val)}
                        updatedStatus={selectedDuration}
                        durationList={durationList} />
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                    <Button variant='contained' onClick={() => { setOpenPopupForWordCount(true) }}>
                        View Suggested Labels
                    </Button>
                    {hasPermission("needs", "update") && <Button variant='outlined' onClick={() => { setOpenPopup(true) }} sx={{ ml: 2 }}>
                        + Create Incident Label
                    </Button>}
                </Box>
            </Box>
            <Box mt={4} display={'flex'} flexDirection={'row'} flexWrap="wrap">
                {needsLabels?.list?.map((label: string) => {
                    return <Box
                        onClick={() => {
                            setSelectedLabel(label);
                            setStepper(2)
                        }}
                        sx={{
                            backgroundColor: '#F5E6F5',
                            border: '1px solid #91278F',
                            borderRadius: 3,
                            mb: 1

                        }}
                        px={3} py={0.8} mr={2} >
                        <Typography variant='body2' sx={{ cursor: 'pointer' }}>
                            {label}
                        </Typography>
                    </Box>
                })}
            </Box>
            <Box mt={1}>
                <Typography variant='caption' color='#7C797C'>
                    Tap on the label you wish to see grievances for
                </Typography>
            </Box>
            {openPopup && <CreateLabelPopup
                createLabel={(label) => { createLabelApiCall(label) }}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup} />}
            <WordCountPopup
                needsSuggestedLabels={needsSuggestedLabels?.map((item) => {
                    return { text: item?.incidentLabel, value: item?.count }
                }) || []}
                openPopup={openPopupForWordCount}
                setOpenPopup={setOpenPopupForWordCount}
            />
            <CardActions
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <Box
                    mt={1}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        pl: 3,
                        pr: 2,
                    }}
                >
                    <Typography variant="body2">{customText}</Typography>
                    <TablePagination
                        component="div"
                        labelRowsPerPage={customText}
                        rowsPerPageOptions={[]}
                        count={needsLabels?.total}
                        rowsPerPage={8}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </CardActions>
        </Box>
    )
}
