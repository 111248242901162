import React, { useState } from 'react'
import MainLayout from '../../../../layouts/main/main_layout';
import { Box, Button, Card, CardContent, Divider, Grid, IconButton, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import { ArrowBack, DeleteOutline } from '@mui/icons-material';
import Loading from '../../../../components/loading/loading';
import { useNavigate, useParams } from 'react-router-dom';
import XLSIcon from "../../../../assets/xls_icon.svg";
import { useQuery } from 'react-query';
import { deleteClient, getIndividualClientDetails } from '../../../../services/client_mapping/client_mapping';
import { ClientDetail } from '../../model/client_details';
import DeleteUser from '../delete_user/delete_uer';
import RevokeAccess from '../revoke_access/revoke_access';
import { useFeedback } from '../../../../providers/feedback/feeedback';
import { client_mapping } from '../../../../routes/routes_path';


export default function ClientDetails() {
    const [deleteAdmin, setDeleteAdmin] = useState(false);
    const { id } = useParams()
    const navigate = useNavigate();
    const { showSnackbar } = useFeedback();

    const { data: details } = useQuery<ClientDetail>(
        "individual-Client-Details",
        async () => {
            let { data } = await getIndividualClientDetails({ id: +id! });
            return data?.data;
        }
    );


    const deleteAdminApiCall = () => {
        deleteClient({ clientId: +id! })
            .then((res) => {
                setDeleteAdmin(false);
                showSnackbar(res?.data?.data?.message || "");
                navigate(client_mapping)
            })
            .catch((e) => {
                setDeleteAdmin(false);
                showSnackbar("Something Went wrong");
            });
    };

    function listItem({
        title,
        value,
    }: {
        title: string;
        value: any;
    }): JSX.Element {
        console.log(details, 'value');
        return (
            <Box>
                <Typography color="primary.main">{title}</Typography>
                <Typography>{value || "-"}</Typography>
            </Box>
        );
    }
    function IconComponent(
        imgSrc: string,
        backgroundColor: string,
        onTap?: () => void
    ) {
        return (
            <Box
                sx={{
                    display: "flex",
                    backgroundColor: backgroundColor,
                    justifyContent: "center",
                    alignItems: "center",
                    p: 1,
                    borderRadius: 3,
                    ml: 2,
                }}
            >
                <IconButton
                    onClick={onTap}
                    sx={{
                        height: 24,
                        width: 24,
                    }}
                >
                    <img src={imgSrc} alt="" />
                </IconButton>
            </Box>
        );
    }
    return (
        <MainLayout>
            <Grid container spacing={3}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box display="flex" alignItems="center">
                                    <IconButton onClick={() => navigate(-1)}>
                                        <ArrowBack></ArrowBack>
                                    </IconButton>
                                    <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                                        View Details
                                    </Typography>
                                </Box>
                                <Box
                                    flex={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="end"
                                ></Box>
                            </Box>
                            <Box mt={2} mb={2}>
                                <Divider />
                            </Box>
                            <Box m={2}>
                                {false ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "70vh",
                                            width: "100%",
                                        }}
                                    >
                                        <Loading />
                                    </Box>
                                ) : (
                                    <Box overflow="auto" flex={1}>
                                        <Grid container spacing={3}>
                                            <Grid item md={6} sm={12}>
                                                {listItem({
                                                    title: "Client Name",
                                                    value: details?.clientName,
                                                })}
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                {listItem({
                                                    title: "Email Id",
                                                    value: details?.emailId,
                                                })}
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                <Box>
                                                    <Typography color="primary.main">{'Module'}</Typography>
                                                    {details?.module?.map((i: string) =>
                                                        <Typography>{i || "-"}</Typography>
                                                    )}
                                                </Box>
                                            </Grid>

                                            <Grid item md={6} sm={12}>
                                                {listItem({
                                                    title: "Role Assigned",
                                                    value: details?.roleAssigned,
                                                })}
                                            </Grid>
                                            {/* <Grid item md={6} sm={12}>
                                                {listItem({
                                                    title: "Type",
                                                    value: '-',
                                                })}
                                            </Grid> */}
                                            {details?.module.includes('needs') &&
                                                <>
                                                    <Grid item md={6} sm={12}>
                                                        {listItem({
                                                            title: "Category",
                                                            value: details?.category?.name,
                                                        })}
                                                    </Grid>
                                                    <Grid item md={6} sm={12}>
                                                        {listItem({
                                                            title: "State",
                                                            value: details?.state?.name,
                                                        })}
                                                    </Grid>
                                                    <Grid item md={6} sm={12}>
                                                        <Box>
                                                            <Typography color="primary.main">{'Area'}</Typography>
                                                            {details?.area?.map((i: { id: number, name: string }) =>
                                                                <Typography>{i?.name || "-"}</Typography>
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={6} sm={12}>
                                                        <Box>
                                                            <Typography color="primary.main">{'City'}</Typography>
                                                            {details?.city?.map((i: { id: number, name: string }) =>
                                                                <Typography>{i.name || "-"}</Typography>
                                                            )}
                                                        </Box>
                                                    </Grid></>}
                                            {details?.module.includes('schemes') &&
                                                <Grid item md={12} sm={12}>
                                                    <Typography color="primary.main">{'Schemes'}</Typography>
                                                    {details?.schemeDetails?.map((item) => {
                                                        return <>
                                                            <ListItem
                                                                sx={{
                                                                    margin: 0,
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <ListItemText
                                                                    primary={item?.name}
                                                                    secondary={
                                                                        <Box display="flex" flexDirection="row">
                                                                            <Typography variant="body2">{item.schemeGovernmentType}</Typography>
                                                                            <Box display={"flex"} flexDirection={'row'} mb={1}>
                                                                                <Typography
                                                                                    color="primary.main"
                                                                                    variant="body2"
                                                                                    textTransform={"capitalize"}
                                                                                    ml={1}
                                                                                    mr={1}
                                                                                >
                                                                                    {item?.schemeCategory}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    primaryTypographyProps={{
                                                                        color: "#1B001B",
                                                                        fontWeight: 600
                                                                    }}
                                                                    secondaryTypographyProps={{
                                                                        color: "#91278F",
                                                                    }}
                                                                />
                                                            </ListItem>
                                                        </>
                                                    })}
                                                </Grid>}
                                        </Grid>
                                        <Box
                                            display={'flex'}
                                            width={'100%'}
                                            height={1.5}
                                            my={3}
                                            sx={{ backgroundColor: '#7C797C', opacity: 0.2 }}

                                        />
                                        <Box display="flex" alignItems="center" mt={3}>
                                            <Typography>Download as:</Typography>
                                            {IconComponent(XLSIcon, "#E9F0ED", () => {

                                            })}
                                        </Box>
                                    </Box>
                                )}

                            </Box>

                            <Grid container mt={3}>
                                <Grid item md={6} lg={6} sm={12}>
                                    <Button
                                        variant="text"
                                        sx={{
                                            pl: 2,
                                            pr: 2,
                                            textTransform: "capitalize",
                                            fontWeight: 600,
                                            minWidth: 200,

                                        }}
                                        onClick={() => navigate(-1)}
                                    >
                                        Back To Client Mapping
                                    </Button>
                                </Grid>
                                <Grid display={'flex'} item md={6} lg={6} sm={12} justifyContent={'flex-end'}>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            pl: 2,
                                            pr: 2,
                                            textTransform: "capitalize",
                                            fontWeight: 600,
                                            minWidth: 200,
                                        }}
                                        onClick={() => setDeleteAdmin(true)}
                                    >
                                        Revoke Access
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <RevokeAccess
                deleteAdminApiCall={deleteAdminApiCall}
                open={deleteAdmin}
                setDeleteAdmin={setDeleteAdmin}
            />
        </MainLayout >
    )
}
