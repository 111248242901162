import { Autocomplete, Box, Button, Dialog, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useFeedback } from '../../../../providers/feedback/feeedback';
import { Attachment, Close } from '@mui/icons-material';

interface Props {
    openPopup: boolean;
    setOpenPopup: (val: boolean) => void;
    updateNeed: (statusId: number, feedback: string, selectedFile: File | null) => void
}

const statusList: { id: number, name: string }[] | null =
    [{ id: 2, name: 'In Progress' }, { id: 3, name: 'Closed' }]

export default function BulkChangeStatusPopup({ openPopup, setOpenPopup, updateNeed }: Props) {
    const [selectedValue, setSelectedValue] = useState<{ id: number, name: string } | null>(statusList?.[0] || null);
    const [closingFeedback, setClosingFeedback] = useState('');
    const { showSnackbar } = useFeedback()
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const maxFileSize = 10 * 1024 * 1024;
        if (file != null) {
            if (file.size > maxFileSize) {
                showSnackbar("File size exceeds 10 MB");
            } else {
                setSelectedFile(file)
            }

        }
    };

    return (
        <Dialog open={openPopup} sx={{ borderRadius: 16 }}>
            <DialogContent style={{ minWidth: "500px", borderRadius: 20 }}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant="body1" fontWeight={600} textAlign={"center"} mt={3}>
                        Change Status
                    </Typography>
                </Box>

                <Box mt={2}>
                    <Autocomplete
                        fullWidth
                        value={selectedValue}
                        onChange={(e, value) => {
                            setSelectedValue(value)
                        }}

                        options={statusList || []}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={"Mark As"}
                                placeholder={"Select Status"}
                            />
                        )}
                    />
                </Box>

                {selectedValue?.id === 3 && <Box mt={2} position={"relative"}>
                    <TextField
                        value={closingFeedback}
                        fullWidth
                        multiline
                        rows={9}
                        label='Closing Feedback'
                        onChange={(e) => { setClosingFeedback(e.target.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            maxLength: 300,
                        }}
                    />
                    <Typography
                        mt={1}
                        variant="caption"
                        sx={{ position: "absolute", bottom: 8, left: 12 }}
                    >{`${300 - closingFeedback.length} characters left`}</Typography>
                </Box>}
                {(!selectedFile && selectedValue?.id === 3) &&
                    <>
                        <Box mt={2} p={1} sx={{ border: '1px solid #F5E6F5', borderRadius: 2 }}>
                            <label htmlFor="fileUpload" className="custom-file-upload">
                                <IconButton component="span">
                                    <Attachment sx={{ color: '#7C797C' }} />
                                </IconButton>
                            </label>
                            <input
                                className="custom-file-upload"
                                id="fileUpload"
                                onChange={handleFileChange}
                                type="file"
                                style={{ display: 'none' }}
                                accept=".png, .jpg, .jpeg, .mp4, .mp3"
                            />
                            <label htmlFor="fileUpload" className="custom-file-upload">
                                <Button component="span" sx={{ color: "#7C797C" }}>Click here to upload image or video or audio</Button>
                            </label>

                        </Box>
                        <Box mt={1}>
                            <Typography variant="caption">
                                Upload a .png, .jpeg, jpeg, .mp4 and .mp3 document with maximum size of 10 MB
                            </Typography>
                        </Box>
                    </>}

                {selectedFile && <Box display={'flex'} flexDirection={'row'} mt={1}>
                    <Typography mt={1}>{selectedFile?.name}</Typography>
                    <IconButton onClick={() => setSelectedFile(null)}>
                        <Close />
                    </IconButton>
                </Box>}

                <Box display={'flex'} mt={4} justifyContent={'space-between'}>
                    <Button onClick={() => {
                        setOpenPopup(false)
                    }}
                        variant='text'
                    >
                        Close
                    </Button>
                    <Button
                        disabled={selectedValue?.id === 3 ? !closingFeedback : false}
                        onClick={() => {

                            updateNeed(selectedValue?.id!, closingFeedback, selectedFile)
                        }}
                        sx={{ minWidth: 200 }}
                        variant='contained'
                        size='large'
                    >
                        Save
                    </Button>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

