import React, { useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useUser from "../../providers/user/user_provider";
import { ArrowBack, Search } from "@mui/icons-material";
import FilterIcon from "../../assets/filter_icon.svg";
import AccessDenied from "../../components/access_denied/access_denied";
import styled from "@emotion/styled";
import DatGridRewards from "./component/data_grid_rewards/data_grid_rewards";
import FilterRewards from "./component/filter_rewards/filter_rewards";
import { getRewardsList } from "../../services/rewards/rewards";
import { useQuery } from "react-query";
import Loading from "../../components/loading/loading";
import { getMasterData } from "../../services/choices/choices_services";
import { NeedFilters } from "../needs/model/needs_filters";
import { getNeedsFilters } from "../../services/needs/needs_service";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export interface RewardsListingLabels {
  label: string;
}

export default function Rewards() {
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [rewardsRangeFrom, setRewardsFrom] = useState<number | null>(null);
  const [rewardsRangeTo, setRewardsRangeTo] = useState<number | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [ageFrom, setAgeFrom] = useState<number | null>(null);
  const [ageTo, setAgeTo] = useState<number | null>(null);
  const [area, setArea] = useState("");
  const [gender, setGender] = useState("");
  const [stateId, setStateId] = useState<number | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const [districtId, setDistrictId] = useState<number | null>(null);

  const { data: masterDataList } = useQuery(["masterDataList"], async () => {
    let response = await getMasterData();
    return response.data.data;
  });

  const { data: needsFilters } = useQuery<NeedFilters>(
    ["needs-filters"],
    async () => {
      let data = await getNeedsFilters();
      return data?.data?.data;
    }
  );

  const { data: rewardsListData, isLoading } = useQuery(
    ["rewardList",
      rewardsRangeFrom,
      rewardsRangeTo,
      searchValue,
      page,
      startDate,
      endDate,
      ageFrom,
      ageTo,
      area,
      gender,
      cityId,
      stateId
    ],
    async () => {
      let response = await getRewardsList({
        page: page + 1,
        rewardFrom: rewardsRangeFrom,
        rewardTo: rewardsRangeTo,
        size: 8,
        keyword: searchValue,
        startDate: startDate,
        endDate: endDate,
        ageFrom: ageFrom,
        ageTo: ageTo,
        area: area,
        gender: gender,
        city: cityId,
        stateId: stateId,
        district: districtId,
      });
      return response?.data?.data;
    }
  );

  function searchComponent() {
    return (
      <Grid display={"flex"} flexDirection={"row"} container p={2} mt={2}>
        <Grid md={12} sm={12} xs={12} lg={12} xl={12}>
          <TextField
            value={searchValue}
            onChange={(e) => {
              setPage(0);
              setSearchValue(e.target.value);
            }}
            size="small"
            fullWidth
            sx={{
              backgroundColor: "#F5E6F5",
              borderRadius: 3,
              borderColor: "transparent",
            }}
            placeholder="Search by User Name and contact number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "text.primary" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }
  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
          height: "fit-content",
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }

  const rewardsDatagridLabels: RewardsListingLabels[] = [
    {
      label: "User ID",
    },
    {
      label: "Full Name",
    },
    {
      label: "Contact Number",
    },
    {
      label: "Date of Birth",
    },
    {
      label: "Gender",
    },
    {
      label: "Marital Status",
    },
    {
      label: "State",
    },
    {
      label: "District",
    },
    {
      label: "Total Reward Points",
    },
  ];

  return (
    <MainLayout>
      <Card
        sx={{
          display: "flex",
          width: "100%",
          minHeight: 600,
          mb: 2,
          minWidth: 1200,
          mt: 2,
        }}
      >
        {hasPermission("rewards", "read") ? (
          <Box
            display="flex"
            flexDirection={"column"}
            sx={{ width: "100%" }}
            mt={2}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}
              p={2}
            >
              <Box flexDirection="row" display="flex" alignItems="center">
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack></ArrowBack>
                </IconButton>
                <Typography variant="h6" fontWeight="600">
                  Reward Points
                </Typography>
              </Box>
              <Box display="flex">
                <Typography
                  display="flex"
                  alignItems={"center"}
                  color="#91278F"
                  fontWeight="600"
                  sx={{ borderBottom: "3px solid #91278F" }}
                >
                  Filters:
                </Typography>
                {IconComponent(FilterIcon, "#E9F0ED", () =>
                  setOpenFilterModel(true)
                )}
              </Box>
            </Box>
            {searchComponent()}
            {isLoading ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Loading />
              </Box>
            ) : (
              <DatGridRewards
                count={rewardsListData?.total || 0}
                data={rewardsListData?.list || []}
                labels={rewardsDatagridLabels}
                page={page}
                setPage={setPage}
              />
            )}
          </Box>
        ) : (
          <Box display="flex" flex={1} width="100%" height="100vh">
            <AccessDenied />
          </Box>
        )}
      </Card>
      <FilterRewards
        setDistrictId={setDistrictId}
        districtId={districtId}
        city={cityId}
        setCity={setCityId}
        genderList={masterDataList?.gender}
        states={masterDataList?.state || []}
        cities={needsFilters?.cityList || []}
        stateId={stateId}
        setStateId={setStateId}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        ageFrom={ageFrom}
        setAgeFrom={setAgeFrom}
        setAgeTo={setAgeTo}
        setArea={setArea}
        setGender={setGender}
        setPage={setPage}
        ageTo={ageTo}
        area={area}
        gender={gender}
        cityId={cityId}
        setCityId={setCityId}
        openModal={openFilterModel}
        resetFilter={() => {
          setRewardsFrom(null);
          setRewardsRangeTo(null);
          setStartDate("");
          setEndDate("");
          setAgeFrom(null);
          setAgeTo(null);
          setGender("");
          setArea("");
          setStateId(null);
          setCityId(null);
          setDistrictId(null)

        }}
        setOpenModal={setOpenFilterModel}
        setRewardsFrom={setRewardsFrom}
        setRewardsTo={setRewardsRangeTo}
      />
    </MainLayout>
  );
}
