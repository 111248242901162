import React from 'react'
import { SchemeMaster } from '../../../schemes/models/scheme_master';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';

interface props {
    onSubmit: () => void;
    stepperCount: number;
    setStepperCount: (val: number) => void;
    masterDataList: SchemeMaster;
    areaOfInterest: number[] | null;
    setAreaOfInterest: (val: number[]) => void;
    modifyUser?: boolean | undefined;
    disableSaveBtn?: boolean;
    setDisableSaveBtn?: (val: boolean) => void
}


export default function AreaOfInterest({
    stepperCount,
    onSubmit,
    setStepperCount,
    masterDataList,
    areaOfInterest,
    setAreaOfInterest,
    modifyUser,
    disableSaveBtn = false,
    setDisableSaveBtn
}
    : props) {
    return (
        <Box mt={3}>
            {masterDataList?.areasOfInterest?.map((item) => {
                if (item?.value === 'All') return null
                return <Chip
                    sx={{ mr: 1, mb: 2 }}
                    label={item?.value}
                    variant={
                        !!areaOfInterest?.includes(item?.id) ? 'filled' : 'outlined'
                    }

                    color={!!areaOfInterest?.includes(item?.id) ? 'primary' : 'default'}
                    clickable
                    onClick={() => {
                        if (areaOfInterest !== null) {
                            if (areaOfInterest?.includes(item?.id)) {
                                setAreaOfInterest(areaOfInterest.filter((i) => i !== item?.id))
                            } else {
                                setAreaOfInterest([...areaOfInterest!, item?.id])
                            }
                        } else {
                            setAreaOfInterest([item?.id]);
                        }
                        setDisableSaveBtn?.(false)
                    }}
                />

            })}
            <Grid mt={2} display={'flex'} item md={12} lg={12} sm={12} xs={12} justifyContent={'end'}>
                {!!!modifyUser && <Button
                    variant='outlined'
                    sx={{ minWidth: 150 }}
                    onClick={() => setStepperCount(stepperCount - 1)}
                >
                    Back
                </Button>}
                <Button
                    disabled={disableSaveBtn}
                    variant='contained'
                    onClick={onSubmit}
                    sx={{ ml: 1, minWidth: 150 }}>
                    {!!!modifyUser ? "Proceed and Create User" : "Save Changes"}
                </Button>
            </Grid>

        </Box>
    )
}
