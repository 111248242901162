import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import DatGridUsers from "./components/datagrid/datagridUsers";
import Loading from "../../components/loading/loading";
import FilterIcon from "../../assets/filter_icon.svg";
import { ArrowBack, Search } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import FilterUsers from "./components/filter/filterUsers";
import { getMasterData } from "../../services/master/master_service";
import { useQuery } from "react-query";
import {
  appUsersService,
  deleteAppUser,
  downloadAppUsers,
  getAppUsers,
  getUsersDownloadList,
  usersDownload,
} from "../../services/app_users/app_users";
import BlockAppUser from "./components/block_app_user_popup/block_app_user";
import DeleteAppUser from "./components/block_app_user_popup/block_app_user";
import { useFeedback } from "../../providers/feedback/feeedback";
import { queryClient } from "../../providers/query/query";
import { AppUsersList } from "./model/app_users_list_model";
import XLSIcon from "../../assets/xls_icon.svg";
import { downloadFromBlob } from "../../utils/utils";
import useUser from "../../providers/user/user_provider";
import AccessDenied from "../../components/access_denied/access_denied";
import DownloadUsersPopup from "./components/download_users_popup/download_users_popup";
import { create_user } from "../../routes/routes_path";
import Sort from "../../assets/sort.svg";
import { getNeedsFilters } from "../../services/needs/needs_service";
import { NeedFilters } from "../needs/model/needs_filters";
import DataGridDownloads from "./components/data_grid_downloads/data_grid_downloads";
import UsersDownloadFilters from "./components/users_filter_for_downloads/download_filter";

export interface UsersLabel {
  label: string;
}

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});
export interface UsersResponseLabel {
  label: string;
}
export default function Users() {
  const { showSnackbar } = useFeedback();
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [schemeKeyword, setSchemeKeyword] = useState("");
  const [stateId, setStateId] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedGender, setSelectedGender] = useState<string>("");
  const [blockAppUserDialog, setBlockAppUserDialog] = useState<boolean>(false);
  const [deleteAppUserDialog, setDeleteAppUserDialog] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<AppUsersList | null>(null);
  const [ageFrom, setAgeFrom] = useState<number | null>(null);
  const [ageTo, setAgeTo] = useState<number | null>(null);
  const [educationId, setEducationId] = useState<number | null>(null);
  const [incomeId, setIncomeId] = useState<number | null>(null);
  const [occupationId, setOccupationId] = useState<number | null>(null);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [fromPageForDownload, setFromPageForDownload] = useState<number | null>(
    null
  );
  const [toPageForDownload, setToPageForDownload] = useState<number | null>(
    null
  );
  const [openDownloadUsersPopup, setOpenDownloadPopup] = useState(false);
  const [cityId, setCityId] = useState<number | null>(null);
  const [sortInAcceding, setSortInAcceding] = useState(true);
  const [sortInAccedingForDownloads, setSortInAccedingForDownloads] = useState(true);
  const [districtId, setDistrictId] = useState<number | null>(null);
  const { hasPermission } = useUser();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [openModelForDownloads, setOpenModalForDownloads] = useState(false);
  const [pageForDownloads, setPageForDownloads] = useState(0);
  const [status, setStatus] = useState<number | null>(null);
  const [startDateForDownloads, setStartDateForDownloads] = useState("");
  const [endDateForDownloads, setEndDateForDownloads] = useState("");


  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };


  const { data: masterDataList } = useQuery(["masterDataList"], async () => {
    let response = await getMasterData();
    return response.data.data;
  });

  const { data: needsFilters } = useQuery<NeedFilters>(
    ["needs-filters"],
    async () => {
      let data = await getNeedsFilters();
      return data?.data?.data;
    }
  );

  const { data: usersDownloadList, } = useQuery(
    [
      "usersDownloadList",
      sortInAccedingForDownloads,
      startDateForDownloads,
      endDateForDownloads,
      status,
      currentTab,
      pageForDownloads
    ],
    async () => {
      let sortOrder = sortInAccedingForDownloads ? "DESC" : "ASC";
      let response = await getUsersDownloadList({
        page: pageForDownloads + 1,
        size: 8,
        dateFrom: startDateForDownloads,
        dateTo: endDateForDownloads,
        sortOrder: sortOrder,
        status: status
      });
      return response.data.data?.data;
    }
  );

  const { data: appUsers, isLoading } = useQuery(
    [
      "app-Users",
      page,
      ageFrom,
      ageTo,
      educationId,
      selectedGender,
      incomeId,
      occupationId,
      stateId,
      searchValue,
      isSubscribed,
      sortInAcceding,
      startDate,
      endDate,
      cityId,
      districtId
    ],
    async () => {
      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      let response = getAppUsers({
        size: 8,
        page: page + 1,
        ageFrom: ageFrom,
        ageTo: ageTo,
        educationId: educationId,
        gender: selectedGender,
        incomeId: incomeId,
        occupationId: occupationId,
        stateId: stateId,
        keyword: searchValue,
        isSubscribed: isSubscribed,
        sortOrder: sortOrder,
        startDate: startDate,
        endDate: endDate,
        city: cityId,
        district: districtId
      }).then((res) => {
        return res.data.data.data;
      });
      return response;
    }
  );

  function usersDownloadApiCall(taskId: number) {
    usersDownload({ taskId: taskId }).then((res) => {
      let extension = ".xlsx";
      downloadFromBlob(res, extension);

    }).catch((e) => { })
  }

  function downloadExcel(fromPage?: number | null, toPage?: number | null) {
    let sortOrder = sortInAcceding ? "DESC" : "ASC";

    downloadAppUsers({
      page: 1,
      size: 8,
      ageFrom: ageFrom,
      ageTo: ageTo,
      educationId: educationId,
      gender: selectedGender,
      incomeId: incomeId,
      occupationId: occupationId,
      stateId: stateId,
      keyword: searchValue,
      format: "excel",
      isSubscribed: isSubscribed,
      sortOrder: sortOrder,
      startDate: startDate,
      endDate: endDate,
      city: cityId,
    }).then((response) => {
      showSnackbar(response?.data?.data?.message);
    }).catch((e) => {
      showSnackbar(e?.response?.data?.error?.message);
    });

  }

  function deleteAppUserApiCall(comments: string) {
    deleteAppUser({ userId: +selectedUser?.id!, comments: comments })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("User deleted successsfully");
          queryClient.invalidateQueries({ queryKey: ["app-Users"] });
        } else {
          showSnackbar("Something went wrong");
        }
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
      });
  }

  const updateUserAccessApiCall = (comments: string) => {
    appUsersService({
      comments: comments,
      isBlocked: !selectedUser?.isBlocked,
      userId: selectedUser?.id!,
    })
      .then((res) => {
        if (res.status === 200) {
          if (selectedUser?.isBlocked) {
            showSnackbar("User Unblocked successfully");
          } else {
            showSnackbar("User blocked successfully");
          }
        } else {
          showSnackbar("Something went wrong");
        }
        queryClient.invalidateQueries({ queryKey: ["app-Users"] });
      })
      .catch((e) => { });
  };

  function updateUserAccess(comments: string) {
    setBlockAppUserDialog(false);
    updateUserAccessApiCall(comments);
  }

  const usersLabels: UsersLabel[] = [
    {
      label: "User id",
    },
    {
      label: "Full Name",
    },
    {
      label: "Contact Number",
    },
    {
      label: "Date of birth",
    },
    {
      label: "Gender",
    },
    {
      label: "Marital Status",
    },
    {
      label: "State",
    },
    {
      label: "District",
    },
    {
      label: "Subscribed",
    },
    {
      label: "Action",
    },
  ];

  let statusList: { id: number, value: string }[] = [
    {
      id: 1, value: 'Pending'
    },
    {
      id: 2, value: 'Completed'
    },
    {
      id: 3, value: 'Failed'
    }
  ]

  const usersDownloads: { label: string }[] = [
    {
      label: "SL. No",
    },
    {
      label: "Status",
    },
    {
      label: "Remark",
    },

    {
      label: "Processed By",
    },
    {
      label: "Processed At",
    },
    {
      label: "Record Count",
    },
    {
      label: "Action",
    },
  ];

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }
  function searchComponent() {
    return (
      <Grid display={"flex"} flexDirection={"row"} container p={2}>
        <Grid md={12} sm={12} xs={12} lg={12} xl={12}>
          <TextField
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            size="small"
            fullWidth
            sx={{
              backgroundColor: "#F5E6F5",
              borderRadius: 3,
              borderColor: "transparent",
            }}
            placeholder="Search by username or contact number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "text.primary" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }

  useEffect(() => {
    if (page !== 0) setPage(0);
  }, [searchValue]);
  return (
    <MainLayout>
      {hasPermission("users", "read") ? (
        <Card
          sx={{
            display: "flex",
            minHeight: 600,
            mb: 2,
            width: "100%",
            mt: 4,
          }}
        >
          <Box
            display="flex"
            flexDirection={"column"}
            mt={4}
            height="100%"
            flex={1}
          >
            <Box flexDirection="row" display="flex" alignItems="center" ml={2}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack></ArrowBack>
              </IconButton>
              <Typography variant="h6" fontWeight="600" >
                Users
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              p={2}
            >
              <Grid item md={12} lg={12} sm={12}>
                <Tabs
                  value={currentTab}
                  onChange={handleChange}
                  sx={{
                    "MuiTabs-root css-112qdbf-MuiTabs-root": {
                      borderColor: "#F5E6F5",
                    },
                    mt: 2,
                  }}
                >
                  <Tab
                    label="Users Details"
                    sx={{
                      textTransform: "capitalize",
                      color: "#1B001B",
                    }}
                  />
                  <Tab
                    label="Downloads"
                    sx={{ textTransform: "capitalize", color: "#1B001B" }}
                  />
                </Tabs>
              </Grid>


            </Box>
            {currentTab === 0 && <Box>
              <Box display={"flex"} flexDirection={"row"} justifyContent={'flex-end'}>
                <Box display="flex" alignItems="center">
                  <Typography>Generate Report:</Typography>
                  <IconButton
                    sx={{ ml: 2 }}
                    onClick={() => downloadExcel()}
                  >
                    <img src={XLSIcon} alt="" />
                  </IconButton>

                  <Box
                    ml={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                  >
                    <Typography>Sort By: </Typography>
                    <Box
                      ml={1}
                      display="flex"
                      flexDirection={"row"}
                      alignItems="center"
                      mr={1}
                    >
                      <Typography color={"#91278F"}>
                        {sortInAcceding ? "New to old" : "Old to New"}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setSortInAcceding(!sortInAcceding);
                        }}
                      >
                        <img src={Sort} alt="" />
                      </IconButton>
                    </Box>
                  </Box>

                  <Typography
                    display="flex"
                    alignItems={"center"}
                    color="#91278F"
                    fontWeight="600"
                    ml={2}
                    sx={{ borderBottom: "3px solid #91278F" }}
                  >
                    Filters:
                  </Typography>
                  {IconComponent(FilterIcon, "#E9F0ED", () => {
                    setOpenModal(true);
                  })}
                  <Box ml={2} onClick={() => navigate(create_user)}>
                    <Button variant="contained" >
                      Create user
                    </Button>
                  </Box>
                </Box>
              </Box>
              {searchComponent()}
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  height="100%"
                >
                  <Loading />
                </Box>
              ) : (
                <DatGridUsers
                  labels={usersLabels}
                  data={appUsers?.list || []}
                  count={appUsers?.total || 0}
                  page={page}
                  setPage={setPage}
                  setBlockAppUserDialog={setBlockAppUserDialog}
                  blockAppUserDialog={blockAppUserDialog}
                  setSelectedUser={setSelectedUser}
                  setDeletePopUp={setDeleteAppUserDialog}
                />
              )}
            </Box>}
            {currentTab === 1 &&
              <Box>

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  width={"100%"}
                  p={2}
                >

                  <Box display={"flex"} flexDirection={"row"}>
                    <Box
                      ml={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                    >
                      <Typography>Sort By: </Typography>
                      <Box
                        ml={1}
                        display="flex"
                        flexDirection={"row"}
                        alignItems="center"
                      >
                        <Typography color={"#91278F"}>
                          {sortInAccedingForDownloads ? "New to old" : "Old to New"}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            setSortInAccedingForDownloads(!sortInAccedingForDownloads);
                          }}
                        >
                          <img src={Sort} alt="" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Typography
                      display="flex"
                      alignItems={"center"}
                      color="#91278F"
                      fontWeight="600"
                      sx={{ borderBottom: "3px solid #91278F" }}
                      ml={1}
                    >
                      Filters:
                    </Typography>
                    {IconComponent(FilterIcon, "#E9F0ED", () => {
                      setOpenModalForDownloads(true);
                    })}
                  </Box>
                </Box>
                <DataGridDownloads
                  count={usersDownloadList?.total || 0}
                  data={usersDownloadList?.list || []}
                  labels={usersDownloads}
                  setPage={setPageForDownloads}
                  page={pageForDownloads}
                  downloadResponse={(id) => {
                    usersDownloadApiCall(id)
                  }}
                />

              </Box>}

          </Box>
        </Card>
      ) : (
        <Box display="flex" flex={1} width="100%" height="100%">
          <AccessDenied />
        </Box>
      )}

      <FilterUsers
        districtId={districtId}
        setDistrictId={setDistrictId}
        cityId={cityId}
        setCityId={setCityId}
        cities={needsFilters?.cityList || []}
        openModal={openModal}
        setOpenModal={setOpenModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        schemeKeyword={schemeKeyword}
        setSchemeKeyword={setSchemeKeyword}
        states={masterDataList?.state || []}
        education={masterDataList?.education || []}
        category={masterDataList?.schemeCategory || []}
        income={masterDataList?.personalIncome || []}
        stateId={stateId}
        setStateId={setStateId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        resetFilter={() => {
          setCategoryId(null);
          setStateId(null);
          setSchemeKeyword("");
          setStartDate("");
          setEndDate("");
          setOccupationId(null);
          setIncomeId(null);
          setAgeFrom(null);
          setAgeTo(null);
          setSelectedGender("");
          setEducationId(null);
          setIsSubscribed(false);
          setCityId(null)
        }}
        genderList={masterDataList?.gender}
        selectedGender={selectedGender}
        setSelectedGender={setSelectedGender}
        setAgeFrom={setAgeFrom}
        setAgeTo={setAgeTo}
        setEducationId={setEducationId}
        setIncomeId={setIncomeId}
        setOccupationId={setOccupationId}
        occupation={masterDataList?.occupation || []}
        setPage={setPage}
        isSubscribed={isSubscribed}
        setIsSubscribed={setIsSubscribed}
      />

      <BlockAppUser
        open={blockAppUserDialog}
        onDialogClose={() => {
          setBlockAppUserDialog(false);
        }}
        updateUserAccess={updateUserAccess}
        title={
          selectedUser?.isBlocked
            ? "Are you sure you want to unblock this user?"
            : "Are you sure you want to block this user?"
        }
        submitBtnLabel={selectedUser?.isBlocked ? "Unblock" : "Block"}
      />
      <UsersDownloadFilters
        status={status}
        setStatus={setStatus}
        statusList={statusList}
        openModal={openModelForDownloads}
        setOpenModal={setOpenModalForDownloads}
        startDate={startDateForDownloads}
        setStartDate={setStartDateForDownloads}
        endDate={endDateForDownloads}
        setEndDate={setEndDateForDownloads}
        resetFilter={() => {
          setStartDateForDownloads("");
          setEndDateForDownloads("");
          setPageForDownloads(0);
          setStatus(null)
        }}
      />
      <DeleteAppUser
        open={deleteAppUserDialog}
        onDialogClose={() => {
          setDeleteAppUserDialog(false);
        }}
        updateUserAccess={(remarks) => {
          setDeleteAppUserDialog(false);
          deleteAppUserApiCall(remarks);
        }}
        title="Are you sure you want to Delete this user?"
        submitBtnLabel={"Delete"}
      />
      <DownloadUsersPopup
        onDialogClose={() => {
          setOpenDownloadPopup(false);
        }}
        open={openDownloadUsersPopup}
        key={1}
        onSubmit={(fromPage: number | null, toPage: number | null) => {
          downloadExcel();
        }}
      />
    </MainLayout>
  );
}
